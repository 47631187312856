import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { motion } from 'framer-motion';
import logo from "../../assets/LOGO_2_New.png";
import DIAAPng from "../../assets/DIAA_Trademark.png";
import { useMediaQuery } from 'react-responsive';

const XL_BREAKPOINT = 1346; // Adjusted breakpoint for XL devices

const AnimatedLogo = () => {
  const [isRevealed, setIsRevealed] = useState(false);
  const isXL = useMediaQuery({ minWidth: XL_BREAKPOINT });

  const handleResize = useCallback(() => {
    setIsRevealed(false);
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const handleInteraction = useCallback(() => {
    if (!isXL) {
      setIsRevealed(prev => !prev);
    }
  }, [isXL]);

  const logoVariants = useMemo(() => ({
    initial: { x: 0 },
    slide: { x: isXL ? -120 : 0, transition: { duration: 0.5, ease: "easeInOut" } }
  }), [isXL]);

  const textVariants = useMemo(() => ({
    hidden: {
      x: 0,
      clipPath: 'inset(0 100% 0 100%)',
    },
    visible: {
      x: 0,
      clipPath: 'inset(0 0% 0 0)',
      transition: {
        duration: 0.5,
        ease: "easeOut",
        clipPath: { duration: 0.5, delay: 0.2 },
      }
    }
  }), []);

  return (
    <div
      className="cursor-default mobile:overflow-hidden"
      onMouseLeave={isXL ? () => setIsRevealed(false) : undefined}
    >
      <motion.div
        onMouseEnter={isXL ? () => setIsRevealed(true) : undefined}
        variants={logoVariants}
        initial="initial"
        animate={isRevealed ? "slide" : "initial"}
        className='md:w-full'
      >
        <img 
          src={logo} 
          alt="logo" 
          className="w-9 sm:w-9 md:w-10 lg:w-12 ml-2 xl:ml-24 absolute mobile:mt-[1px] md:mt-0 mt-[2px] lg:mt-1" 
        />
      </motion.div>

      <motion.div
        variants={textVariants}
        initial="hidden"
        animate={isRevealed ? "visible" : "hidden"}
        className={`mobile:ml-12 mb-1.5 ml-2 ${!isXL ? 'landscape:ml-14' : ''}`}
      >
        <img 
          src={DIAAPng} 
          alt="DIAA" 
          className="w-[120px] sm:w-[160px] md:w-[180px] lg:w-[200px] sm:ml-10 md:ml-0 lg:ml-6 object-contain" 
        />
      </motion.div>
    </div>
  );
};

export default AnimatedLogo;