import React, { useEffect, useState } from 'react'
import laptop from '../assets/SapS4hana/macbookSkeleton.png'; // Ensure you have a high-quality laptop image
import image1 from '../assets/SapS4hana/loio92c4b32791cf432ab5693d65879a39bc_LowRes.png'; // Replace with your actual image paths
import image2 from '../assets/SapS4hana/sap-s4hana-dashboard-screenshot.png';
import image3 from '../assets/SapS4hana/Customers-Overview-2-1.png';
import logo1 from '../assets/SapS4hana/logo1.png';
import logo2 from '../assets/SapS4hana/logo-1-dummy.png';
import logo3 from '../assets/SapS4hana/logo-2-dummy.png';
import logo4 from '../assets/SapS4hana/logo-3-dummy.png';
import imageCover from '../assets/SapS4hana/SapCxCover.png'; 

// import Footer from '../components/Footer';

// pages/SapS4hanaDemo.js
import { Header } from '../components/resuable/SapSolution';
import { InfoCards } from '../components/resuable/SapSolution';
import { Gallery } from '../components/resuable/SapSolution';
import { PaymentCards } from '../components/resuable/SapSolution';
import { BenefitsSection } from '../components/resuable/SapSolution';
import { PartnerLogos } from '../components/resuable/SapSolution';
import { FooterComponent } from '../components/resuable/SapSolution';
import { useDocumentTitle } from '../utils/useDocumentTitle';

const SapCx = () => {
    const headerData = {
        title: "What Is SAP CX",
        description: "The SAP Customer Experience portfolio covers every step in the process, from the first contact a customer has with a company through to when they choose to buy from it. SAP achieves this by integrating various solutions."
    };

    useDocumentTitle(headerData?.title.replace(/\b(what|is)\b/gi, ''));

    const infoCardsData = [
        {
            title: "SAP CX Industries :",
            items: ['Wholesale & Distribution', 'Retail & Restaurants', 'Professional Service']
        },
        {
            title: "SAP CX Availability:",
            items: ['Cloud'],
            icon: 'M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z'
        },
        {
            title: "SAP S/4 HANA Employees:",
            items: ['Enterprise SMB'],
            icon: 'M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z'
        }
    ];

    const galleryData = {
        images: [image1, image2, image3],
        description: "Explore the features and functionalities of SAP S/4 HANA through an interactive gallery."
    };

    const benefitsData = {
        title: "SAP CX Benefits",
        description: "The platform provides instant real time, Architecture, Productivity, performance",
        benefits: [
            {
                icon: '⏱️',
                title: 'UNDERSTAND YOUR AUDIENCE',
                description: 'Understand your audience by anticipating their needs. A unified customer profile from every data source across the enterprise can provide unique insights to help you serve your customers better.'
            },
            {
                icon: '📊',
                title: 'ENGAGE YOUR CUSTOMERS',
                description: 'Engage your customers by acting on insights and delivering hyper-personalized experiences. With our portfolio of solutions you can engage your customers at the right time with the right message.'
            },
            {
                icon: '📄',
                title: 'DELIVER ON YOUR PROMISES',
                description: 'Deliver on your promises – from providing a quick and simple buying experience to having orders ready for a seamless pickup, to showing up on time for a service call. Fulfilling your brand promise is critical to customer satisfaction.'
            },
            {
                icon: '📈',
                title: 'DELIVER THE RIGHT MESSAGE',
                description: 'deliver the right message at the right time and in the right place to drive engagement and inspire customers to act.'
            },
        ]
    };

    const partnerLogosData = {
        title: "SAP S/4 HANA Partners",
        logos: [logo1, logo2, logo3, logo4, logo1]
    };

    return (
        <div className="min-h-screen text-white">
            <Header {...headerData} backgroundImage={imageCover}/>
            <InfoCards cards={infoCardsData} />
            {/* <Gallery {...galleryData} /> */}
            <PaymentCards title={`SAP S/4 HANA Payment Models`} />
            <BenefitsSection {...benefitsData} />
            {/* <PartnerLogos {...partnerLogosData} /> */}
            <FooterComponent />
        </div>
    );
};

export default SapCx;