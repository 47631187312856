import React, { useEffect, useState } from 'react'
import { Header } from '../components/resuable/SapSolution'
import coverImage from '../assets/SAP/SAPCloudCover.png'
import coverImage2 from '../assets/SAP/cloudManagedCover2.png'
import clientImg from '../assets/SAP/ClientImg.png'
import cloudImg1 from '../assets/SAP/cloudManagedimg.png'
import ourSuccessImg1 from '../assets/SAP/ourSuccessImg2.png'
import contactCover from '../assets/SAP/contactCover.png'
import MigrationInfo from '../components/SAP/MigrantInfo';
import { ContactContent, FAQItem, FeatureGrid, scrollToBottom, Slider } from '../components/resuable/SapComponents';
import { AnimatePresence, motion } from 'framer-motion'
import { FaGlobe, FaCloud, FaLock, FaClock, FaGraph, FaRobot, FaDatabase, FaChartBar } from 'react-icons/fa';
import Footer from '../components/Footer'
import { useDocumentTitle } from '../utils/useDocumentTitle'


function CloudManagedServices() {
    useDocumentTitle('Managed Cloud Services');

    const headerData = {
        title: "Managed Cloud Services",
        description: "Modernize your IT on Cloud. Embrace the virtual world to unlock a hyper-agile, scalable, and intelligent enterprise reality."
    };

    const migrationInfoData = {
        stats: [
            { title: "126.45B", subtitle: "Global expenditure on public cloud managed services by 2030 is projected to reach" },
            { title: "70 %", subtitle: "global businesses using managed cloud services today aims to multi-fold spending in the coming years" },
            { title: "77 %", subtitle: "Organizations cite lack of skills as an obstacle to implementing cloud strategies" }
        ],
        mainContent: {
            heading: "Cloud Transformation Decoded: Unreal Performance, Unprecedented Benefits, Jaw-dropping Cost Savings",
            paragraphs: [
                "Cloud has opened doors to a new, more efficient business future. Firms worldwide are rushing to modernize their entire IT backends and their processes, applications, assets on the virtual platforms: Gartner predicts that over 80% of global data flow would be on the cloud by 2025!",
                "Managed Cloud providers are the necessary forces in this transformation, acting as the bridge between clients and cloud vendors. As an end-to-end application-focused managed cloud services provider, DIAA Corp assumes total ownership of your cloud ecosystem. Embrace cutting-edge public-private-hybrid cloud solutions powered by AWS, Azure, Google Cloud Platform (GCP), Oracle, or IBM Cloud architecture. Leverage the best of your adopted cloud platforms rendering hyper agility, high availability, zero disruption, and end-to-end control. Avail 24/7/365 cloud consulting and support, ensuring a frictionless digital transformation on the cloud.",
            ]
        },
    };


    const DIAACorpRecognition = () => {
        return (
            <div className="bg-bg text-white py-8 px-4 mt-12 md:mt-24">
                <div className="md:w-11/12 mx-auto text-center">
                    <h2 className="text-2xl md:text-3xl mobile:text-xl font-bold mb-4">
                        DIAA Corp Named a Visionary in 2021 Gartner® Magic Quadrant™ for Public Cloud IT Transformation Services
                    </h2>
                    <p className="text-sm md:text-base mobile:text-xs leading-relaxed">
                        This is a testament to the unparalleled value DIAA Corp’s cloud-native automation-driven agile solutions such as SHOP or Self-healing Operations Platform add. Across industries, a greater need is felt for new skills, tools, strategies, and processes to materialize the transformation towards a cloud-native future. DIAA Corp’s unfaltering commitment to be instrumental in accelerating the journey and driving business outcomes has reaffirmed our position as a trusted public cloud IT transformation partner in the global arena. This recognition strengthens our belief that our approach is finding resonance among the leading analysts, clients as well as the wider market.
                    </p>
                </div>
            </div>
        );
    };


    const OrganizationsServed = () => {
        return (
            <div className="relative h-screen w-full bg-cover bg-center flex items-center justify-center mt-12 md:mt-24"
                style={{
                    background: `url(${coverImage2})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="relative z-10 text-white text-center">
                    <h1 className="text-4xl mobile:text-2xl font-bold mb-8">We are Proud to Serve 4000+ Organizations across 25 Nations</h1>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 my-12 md:my-24">
                        {[
                            { icon: '🏦', title: 'Global Banks', subtitle: 'Serving 3 of the top 5' },
                            { icon: '🚗', title: 'Global Car Manufacturers', subtitle: 'Serving 2 of the top 5' },
                            { icon: '🎬', title: 'Media Companies', subtitle: 'Catering 2 of the top 5' },
                            { icon: '💊', title: 'Pharma Companies', subtitle: 'Catering 3 of the top 5' },
                            { icon: '💰', title: 'Global Financial Services Companies', subtitle: '2 of the Top 5 trust us' },
                        ].map((item, index) => (
                            <div key={index} className="bg-[#52527E] bg-opacity-50 p-4 rounded-lg flex items-center lg:py-12 lg:px-12">
                                <div className="w-12 h-12 flex items-center justify-center rounded-full bg-gradient-to-r from-[#a100ff] to-[#57CFFF] mr-4">
                                    {item.icon}
                                </div>
                                <div className="text-left">
                                    <h3 className="font-semibold">{item.title}</h3>
                                    <p className="text-sm">{item.subtitle}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };




    const ClientTestimonials = () => {
        const [currentSlide, setCurrentSlide] = useState(1);
        const testimonials = [
            { name: 'Roman Alex', position: 'President of SAP', image: clientImg, text: 'I am pleased and honor to recognize congratulate DIAA CORP as a recipient of the sap partner.' },
            { name: 'Jane Doe', position: 'CTO of TechCorp', image: clientImg, text: 'Working with DIAA CORP has been a game-changer for our cloud strategy.' },
            { name: 'John Smith', position: 'CEO of InnovateCo', image: clientImg, text: 'DIAA CORPs expertise in cloud solutions is unparalleled in the industry.' },
            // Add more testimonials as needed
        ];

        const nextSlide = () => setCurrentSlide((prev) => (prev + 1) % testimonials.length);
        const prevSlide = () => setCurrentSlide((prev) => (prev - 1 + testimonials.length) % testimonials.length);

        useEffect(() => {
            const timer = setInterval(nextSlide, 5000);
            return () => clearInterval(timer);
        }, []);

        return (
            <div className=" text-white py-16 mobile:px-2">
                <div className="mx-auto">
                    <h2 className="text-3xl md:text-4xl mobile:text-2xl font-bold text-center mb-12">What Our Clients Say ?</h2>
                    <div className="relative mb-16 md:w-11/12 mx-auto max-w-6xl">
                        <div className="overflow-hidden">
                            <div className="flex transition-transform duration-300 ease-in-out"
                                style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                                {testimonials.map((testimonial, index) => (
                                    <div key={index} className="w-full flex-shrink-0 px-4">
                                        <div className={`transition-all duration-300 
                                                ${index === currentSlide ? 'scale-100 opacity-100' : 'scale-90 opacity-50'}`}>
                                            <div className="bg-[#14192E] bg-opacity-50 p-6 rounded-lg flex items-center">
                                                <img src={testimonial.image} alt={testimonial.name} className="w-20 h-20 rounded-full mr-6" />
                                                <div>
                                                    <h3 className="font-bold text-xl">{testimonial.name}</h3>
                                                    <p className="text-sm text-gray-400 mb-2">{testimonial.position}</p>
                                                    <p className="mobile:text-sm text-lg">{testimonial.text}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex justify-center mt-8 space-x-4">
                            <button onClick={prevSlide} className="bg-transparent border border-white rounded-full p-2 hover:bg-white hover:bg-opacity-20">
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" /></svg>
                            </button>
                            <button onClick={nextSlide} className="bg-transparent border border-white rounded-full p-2 hover:bg-white hover:bg-opacity-20">
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" /></svg>
                            </button>
                        </div>
                    </div>

                    {/* Roadblocks Section */}
                    <div className="bg-[#14192E] p-8 rounded-lg mt-12 md:mt-24 py-12 md:py-24">
                        <h2 className="mobile:text-xl md:text-3xl font-bold text-center mb-8">
                            Identifying the Major Roadblocks in your<br />cloud adoption journey
                        </h2>
                        <div className="grid md:grid-cols-2 gap-6 md:w-11/12 mx-auto">
                            {[
                                "Multi-vendor lock-in with complicated standalone systems",
                                "Multiple SLAs in the cloud journey resulting in missing deadlines, penalties",
                                "Limited workload and application performance capabilities with low scalability",
                                "Stunted performance visibility and governance over IT processes",
                                "Non-synchronicity of applications and systems leading to a complicated IT Landscape",
                                "Complex, failed data center implementations with minimal vendor support",
                                "Lack of proper consulting, advisory assistance by service providers in the cloud journey",
                                "Sudden outages causing significant operational and transactional losses",
                                "Compromised Security, Limited Risk Management Capabilities of Internal IT Teams",
                                "Failure to achieve business objectives due to Stunted Digital Transformation Capabilities"
                            ].map((point, index) => (
                                <div key={index} className="flex items-start md:text-center">
                                    <div className="w-3 h-3 mt-1.5 mr-3 rounded-full bg-gradient-to-r from-[#a100ff] to-[#57CFFF] flex-shrink-0"></div>
                                    <p className="text-sm">{point}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    const DIAACorpCloudComponent = () => {
        return (
            <div className="bg-darkBlue-900 text-white flex flex-col items-center justify-center p-6">
                <div className="md:text-center mb-8 md:w-11/12">
                    <h1 className="text-2xl md:text-4xl mobile:text-xl font-bold mb-4">
                        Realize Your Enterprise Transformation strategies with DIAA Corp Managed Cloud Services: Your end-to-end Cloud Partner
                    </h1>
                    <div className="flex mobile:flex-col items-center md:space-x-24 my-12 md:my-24">
                        <div className="relative mb-6 p-1 rounded-lg bg-custom-gradient md:w-1/2 ">
                            <img
                                src={cloudImg1}
                                alt="Enterprise Transformation"
                                className="rounded-lg "
                            />
                        </div>
                        <ul className="text-left space-y-2">
                            <li className="flex items-start">
                                <span className="mr-2 bg-custom-gradient text-transparent bg-clip-text">➤</span>
                                99.95% availability and scalable cloud-native architecture
                            </li>
                            <li className="flex items-start">
                                <span className="mr-2 bg-custom-gradient text-transparent bg-clip-text">➤</span>
                                Highly agile asset and workloads performance
                            </li>
                            <li className="flex items-start">
                                <span className="mr-2 bg-custom-gradient text-transparent bg-clip-text">➤</span>
                                Dedicated DIAA Corp partnership with leading hyperscalers as well with rated IV data centers worldwide
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="md:text-center md:w-11/12">
                    <h2 className="text-xl md:text-3xl font-bold mb-4">
                        DIAA Corp Self Healing Operations Platform (SHOP): Automation Enabled Managed Services
                    </h2>
                    <p className='mt-6 md:mt-12'>
                        DIAA Corp SHOP is a low code AI-powered platform that seamlessly integrates different tools and solutions necessary to deliver managed cloud services to enterprises. The intelligent platform brings dozens of diverse operational platforms, applications together including auto-remediation and self-healing onto a single system. This enables the entire infrastructure and applications landscape to be auto-managed through a single pane of glass while providing customers with a holistic view of their IT environments. Guaranteeing concept to delivery in six months, the platform improves engineers’ efficiency while also allowing engineers with less experience, to handle more complex tasks. Avail a universal view and control on your cloud platform and connected IT architecture.
                    </p>
                </div>
            </div>
        );
    };

    const tabs = [
        'Intelligent Automated Operations',
        'Predictive and Preventive',
        'Collective Knowledge',
        'Situational Awareness',
        'Remedial & Autonomous'
    ];

    const content = [
        <p>
            Integrate your cloud architecture with all your existing applications, tools, systems including third-party systems under one intelligent platform. Gain unparalleled control and security over your workflows, automate IT operations to optimize infra costs, and boost organizational productivity.
        </p>,
        <p>
            Predictive and Preventive content goes here.
        </p>,
        <p>
            Collective Knowledge content goes here.
        </p>,
        <p>
            Situational Awareness content goes here.
        </p>,
        <p>
            Remedial & Autonomous content goes here.
        </p>
    ];


    const ContactContent2 = ({ title, sub }) => {
        return (
            <div className='flex flex-col justify-center items-center text-center bg-[#14192E] mobile:h-[293px] md:h-[471px] px-4 relative'>
                <div className='absolute top-0 left-0 w-full h-full'
                    style={{
                        background: `url(${contactCover})`,
                        backgroundSize: 'cover',
                        backgroundPosition: '25% 25%',
                        backgroundRepeat: 'no-repeat',
                        filter: 'blur(5px)',
                    }}></div>
                <div className='md:w-11/12 relative z-10'>
                    <h2 className="text-lg md:text-2xl font-bold mb-4">{title}</h2>
                    <p className='mb-12 mobile:text-sm'>{sub}</p>
                    <motion.button
                        className="bg-custom-gradient text-white font-normal py-2 px-12 rounded-full hover:bg-blue-600 transition duration-300"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={scrollToBottom}
                    >
                        Contact us
                    </motion.button>
                </div>
            </div>
        );
    }

    const tab1 = [
        'Fail-proof cloud migration',
        'AIOps-powered managed services',
        'Hybrid and multi cloud transaction',
        'Cloud monitoring and reporting',
        'Cloud managed Security Services End to end protection'
    ];

    const tab2 = [
        'Compliant cloud offering: Where reliability performance',
        'Disaster recovery and Enterprise backup services',
        'Datacenter and IT modernization',
        'Application management services'
    ];

    const tab3 = [
        'Managed data storage services',
        'Remote workshop management',
        'Managed Service Automation and RPA',
        'DIAA CORP Universal cloud platform'
    ];

    const content1 = [
        <p>Leverage DIAA Corp's industry-leading migration factory approach for automated, fail-proof transition with a few clicks. Businesses can seamlessly embrace Software-as-a-Service (SaaS), Infrastructure-as-a-Service (IaaS), Platform-as-a-Service (PaaS), Desktop-as-a-Service (DaaS), Disaster Recovery as a Service (DRaaS), under a single, pay-per-use SLA. Build your tailored cloud IT environment on AWS, GCP, Azure, IBM, S/4 HANA, or Oracle Cloud.</p>,
        <p>Leverage advanced cloud solutions and AIOps-powered, globally compliant cloud managed services. Set up all infrastructure requirements with ease for maximum availability, scalability, and agility. Gain smart multi cloud management with IT infrastructure modernization and virtualization, network operations administration, automated reporting, and uninterrupted business continuity under a single SLA and cost-efficient pay-per-use model. Automate and modernize key business functionalities and processes on the cloud leveraging DevOps, SysOps, CloudOps.</p>,
        <p>Regardless of industries and sectors, enterprises often have complicated, non-synchronous IT systems that require multiple cloud platforms. With DIAA Corp hybrid multi-cloud management solution, administer all your private and public cloud platforms with ease from a single dashboard. Get complete visibility over your processes, gain universal control, assign access levels and roles, and leverage cutting-edge analytical insights to review infrastructure performances and business impact.</p>,
        <p>Leverage expert, 24/7 health monitoring of multi-cloud platforms, virtual IT Infra, networks, workloads, databases, and applications. Avail centralized visibility over your entire cloud architecture, automated alert mechanisms including threat notifications, analytical reports, and business intelligence. Track the performance of your adopted as-a-service solutions and services with ease, gain unique insights, and augment your business with data-based decision-making.</p>,
        <p>Unlock smart security management on the cloud for your business and secure your networks, data, workflows with ease. With end-to-end cloud managed security services from DIAA Corp, gain advanced managed detection and response capabilities, SIEM-SOAR, managed SOC, intelligent threat prediction-detection-mitigation capabilities powered by industry-leading platforms such as Microsoft, OSINT, STIX TAXII, MISP, etc. Deploy cloud-native security tools on top for end-to-end protection. Avail support from DIAA Corp’s world-class Cyber Security Incident and Response team.</p>
    ];

    const content2 = [
        <p>Don't let compliance issues deter your business operations or strategic visions on the cloud. Migrate and modernize to a 100% compliant-ready Cloud solution. Solve all local, national, international compliance, data residency or data sovereignty requirements with ease on the compliant-ready cloud architecture. Our exhaustive suite of compliance includes PCI-DSS, NESA, SAMA, GDPR, FedRamp, M&A, IRAP, OJK, CSA, OJK, MEITY, RBI. Worldwide standards include dedicated certifications for ISO 27001, ISO 27017, ISO 27018, ISO 20000, ISO 22301, SOC1, SOC2.</p>,
        <p>Avail DIAA Corp’s world-class Disaster Recovery as a Service (DRaaS) offerings including secure colocation services, database backups and recovery, planned runbook creation with DR drills, RTO/RPO and fail-over assistance, uninterrupted network-server infra and architecture support, automated risk management tools, global compliance, and 24/7/365 consultancy support.</p>,
        <p>Modernize your datacenter and IT assets including applications, networks, workloads, servers, and end-point tools onto the cloud with ease. Lift and shift, re-host, re-build, re-architect, or replace assets to leverage the maximum benefits on the cloud. Achieve extra scalability, agility, and continuity irrespective of geographical and timeline disparities. Gain end-to-end support services to modernize your backend infrastructure without any disruption.</p>,
        <p>Leverage full-scale enterprise application migration, modernization, and management services on the cloud. For instance, unlock the best of SAP ERP and its advanced platforms such as S/4 HANA by transitioning your ERP assets on the cloud platform of choice. Automate key workflow functionalities, foster collaborative work, and administer daily operations with ease. Integrated AI tools and solutions boost end-user experience and resource productivity.</p>
    ];


    const content3 = [
        <p>Design a custom, cost-efficient, and hyper-agile data storage solution on your cloud platform of choice. Use the cloud as your primary storage architecture or enhance your DR, Business Continuity Planning with ease. Take the option to create a hybrid data management platform between your chosen public cloud and on-premise environment for high-speed data transfer functionalities. Leverage advanced analytics to gain data intelligence and boost informed decision-making. Advanced cloud-native solutions ensure your data is safe from the most critical disruptions or mal activities.</p>,
        <p>Amp up your post-pandemic resilience strategies with fail-proof, secure, and easily manageable remote workspace solutions on the cloud. Start with virtual desktop/PC solutions such as VDI or DaaS (Desktop-as-a-Service). Administer, track, monitor, and manage daily task flows with ease and unprecedented agility on the cloud.</p>,
        <p>Embrace advanced automation solutions, complementary services, advanced RPA tools to modernize your top line and bottom line activities and automate routine administrative tasks. Leverage solutions like Remote Monitoring and Management, Professional Service Automation, Virtual Patching, Automated Backup and Recovery, Threat Intelligence, Malware Protection, Customer Support Portal, Configuration Management Database Portal, Automated Network Security, Self Healing Operations Platform (SHOP), and more. Continually optimize processes for maximum ROI.</p>,
        <p>Streamline cloud management for your operations and unleash innovations with DIAA Corp Universal Cloud platform. </p>
    ];

    const CloudManagedTabs = ({ }) => {
        return (
            <div className="w-full md:w-11/12 mx-auto p-4 md:p-6 bg-darkBlue-900 text-white rounded-lg mt-8 md:mt-24">
                <h2 className="text-xl md:text-3xl font-bold text-center mb-4 md:mb-8">
                    DIAA Corp End-to-end Intelligent Managed Cloud Services
                </h2>
                <p className='mt-6 md:mt-12 text-center '>
                    Focus on the Core Offering. Leave all your IT operations woes to us. Succeed, scale, and innovate on the cloud.
                </p>
            </div>
        );
    }


    const features = [
        { icon: <FaGlobe />, text: "One of the most trusted Managed Cloud Service Providers in APAC, MEA, and the Americas for 12+ years" },
        { icon: <FaCloud />, text: "World's largest Application-focused, high-end managed services provider with AIOps-driven Managed Cloud Operations" },
        { icon: <FaLock />, text: "Zero Friction Cloud Migration Model with industry-leading Cloud Adoption Factory approach, 25000+ Apps, and Databases migrated" },
        { icon: <FaClock />, text: "Cost-effective Pay-per-use model under single SLA" },
        { icon: <FaGlobe />, text: "4000+ enterprise customers including 60 of Fortune 500 companies including 5 of Top 20 Global Banks" },
        { icon: <FaCloud />, text: "Presence in 25+ nations worldwide including successful deployment of Public, Private, Hybrid, Multi, and Community Cloud platforms" },
        { icon: <FaClock />, text: "24/7 Support backed by 2000+ cloud certified experts, 23 dedicated Centres of Excellence" },
        { icon: <FaDatabase />, text: "Proven SAP on Cloud expertise managing 10,000+ SAP instances and 2300+ TB of HANA Database" },
        { icon: <FaLock />, text: "Dedicated DR on Cloud offerings for heterogeneous, complex environments with automated recovery-backup, fallback-failover mechanisms" },
        { icon: <FaCloud />, text: "End-to-end Managed Cloud Services portfolio with comprehensive DR and BCP services" },
        { icon: <FaLock />, text: "Dedicated Cloud Managed Security Services Expertise, 40+ Security Controls, dedicated SOCS" },
        { icon: <FaRobot />, text: "DIAA Corp proprietary automation solutions including Self-healing Operations, Automation Delivery platforms, and RPA solutions" },
        { icon: <FaChartBar />, text: "1 Billion+ Fail-safe Hosting Hours administering 40000+ VMS" },
        { icon: <FaChartBar />, text: "High availability 99.95%, hyper-scalability, industry-best uptime" },
        { icon: <FaCloud />, text: "Expertise with hundreds of cloud-native tools and applications, best managed according to tailored business needs" },
        { icon: <FaLock />, text: "Strict compliance to regulatory and in-country data residency standards" },
    ];

    const CloudDifference = () => {
        return (
            <FeatureGrid
                title="Why sign-up for Managed Cloud Services from DIAA Corp?"
                features={features}
            />
        );
    };

    const TechStack = () => {
        const categories = [
            { name: 'Networks', items: 'VPN, MPLS, LMS, Load Balancers, Switches, Routers, Network Time Protocol' },
            { name: 'Platform', items: 'Platform, VDI, KVM, Hyper-V, Xen, OVM' },
            { name: 'Operating Systems', items: 'Windows, Linux, HP-UX, Solaris, AIX' },
            { name: 'Databases', items: 'Oracle, Sybase, MS SQL, Mysql, IMD, Hadoop, Mongo' },
            { name: 'Middleware', items: 'IIS, Apache, Tomcat' },
            { name: 'ERP, CRM', items: 'SAP, Oracle, Dynamics, E-Biz, Axapta' },
            { name: 'Apps', items: 'Apps Exchange, Sharepoint, Jboss, Weblogic' },
            { name: 'DaaS', items: 'Citrix VDI, Microsoft WVD, Azure Virtual Desktop (VD), Amazon Workspaces' },
            { name: 'Monitoring Systems', items: 'Zabbix, Syslog, Cloud Monitoring tools' },
            { name: 'Server Management', items: 'Jump Server' },
            { name: 'VM Management', items: 'Virtual Instances -OS [Windows, Linux], Containers - Dockers & Kubernetes on VM, Azure Kubernetes Services, and Azure Native Services, Big Data Platform Storage' },
            { name: 'Compliances', items: 'IRAP, Bank Negara, Central Bank of Oman, SAMA, FINMA, UAE Compliances, RBI, MAS, OJK, GDPR, CSA, PCI-DSS, HIPAA, GXP, International Standards: ISO-27001, ISO-27017, ISO-27018, ISO-22301, ISO-20000, AICPA SOC, AICPA SOC 2' },
            { name: 'Cloud Ops', items: 'Cloud Platform Management, Infrastructure as Code, Performance Optimization, Automation and Monitoring, Service Management and Governance' },
            { name: 'SysOps', items: 'Terraform, Ansible, Azure Automation, CI-CD Solutions' },
            { name: 'DevOps', items: 'Commvault, Replication - ASR, Cloudendure, Veeam, Native DB replication' },
            { name: 'Backup & Continuity', items: 'SIEM, SOAR, Azure Sentinel, Native Cloud Security Tools, ATP, Threat Intelligence, SOC, MDR, EDR, UEBA, Unified Threat Management, IAM, Data Security Management, PIM, DAM, HBSS, Container Security Systems' },
            { name: 'Security', items: 'SAP, Oracle, Dynamics, E-Biz, Axapta' },
            { name: 'Proprietary Platforms', items: 'Self Healing Operations Platforms (SHOP), Universal Cloud Management Portal (UCP), Customer Support Portal (CSP), Myshift' }
        ]

        return (
            <div className='mt-12 md:mt-24'>
                <h2 className="text-xl md:text-3xl font-bold text-center mb-4 md:mb-8">
                    DIAA Corp Intelligent Managed Services Core Tools and Processes
                </h2>
                <p className='mt-2 md:mt-6 text-center mb-12'>
                    DIAA Corp Intelligent Managed Services Core Tools and Processes
                </p>
                <div className="flex flex-col lg:flex-row flex-wrap justify-center items-start bg-[#070727] p-4 gap-4">
                    {categories.map((category, index) => (
                        <div key={index} className="w-full lg:w-auto flex flex-col lg:flex-row gap-4 mb-4">
                            <div className="bg-[#232940] p-4 rounded-lg flex justify-center items-center h-24 lg:w-[260px] mb-2 lg:mb-0">
                                <h2 className="text-white text-lg font-bold text-center">{category.name}</h2>
                            </div>
                            <div className="bg-[#070727] p-4 rounded-lg border border-[#232940] flex-1 lg:w-[712px]">
                                <p className="text-white text-sm lg:text-base">{category.items}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };


    const KnowledgeDeskManagedCloud = () => {
        const [activeStory, setActiveStory] = useState('Panasonic');

        const cards = [
            {
                title: 'Cloud vs. Costs: Master the Balancing Art for a More Resilient Post-COVID Era ',
                description: 'What’s the strategy to leverage maximum managed cloud benefits and the most optimal expenses? How much can it positively impact a business’ post-pandemic strategy?',
            },
            {
                title: 'Application Modernization – Fostering Businesses and their digital transformations',
                description: 'How can you transform and modernize your core applications, datasets, platforms on the cloud? What are its far-reaching benefits and how to avoid the common pitfalls?',
            },
            {
                title: 'Business Agility in the time of crisis ',
                description: 'How do we create an agile business model? How to align daily operations and processes including IT to support an ultra-fast, fail-proof delivery cycle amidst a global crisis?',
            },
            {
                title: 'Decoding the Shared-Security Responsibility Matrix for your business ',
                description: 'Organizational security is a shared responsibility. While managed cloud security providers can help you with tools and solutions, businesses themselves need to improve their practices, processes for a proper risk-proof future.',
            },
            // Add other cards here
        ];

        const stories = {
            "Panasonic": {
                title: 'Panasonic',
                description: 'Globally renowned Japanese manufacturer embraces agile, advanced private cloud managed services to witness breakthrough operational efficiency sustaining 200,000 users concurrently at zero data loss and 95.95% uptime.',
                image: ourSuccessImg1,
            },
            'AbhiBus': {
                title: 'AbhiBus',
                description: 'Global Food Packaging Manufacturer leverages customized SAP HANA Architecture on AWS to achieve breakthrough operational efficiency with 99.95% Uptime.',
                image: ourSuccessImg1,
            },
            'Global Ceramics Brand': {
                title: 'Global Ceramics Brand',
                description: 'Global Food Packaging Manufacturer leverages customized SAP HANA Architecture on AWS to achieve breakthrough operational efficiency with 99.95% Uptime.',
                image: ourSuccessImg1,
            },
            'TimeOut': {
                title: 'TimeOut',
                description: 'Global Food Packaging Manufacturer leverages customized SAP HANA Architecture on AWS to achieve breakthrough operational efficiency with 99.95% Uptime.',
                image: ourSuccessImg1,
            },
            // Add other stories here
        };

        return (
            <div className="text-white p-8 mt-12 md:mt-24 mobile:px-4">

                <h2 className="text-3xl font-bold mb-6 text-center ">Our Success Stories</h2>

                <div className="flex flex-wrap gap-4 mb-8 justify-center mt-12 md:my-12 md:space-x-8">
                    {Object.keys(stories).map((storyKey) => (
                        <button
                            key={storyKey}
                            className={`px-4 md:px-6 py-2 rounded-full text-[#F1F1F1] ${activeStory === storyKey
                                ? 'bg-custom-gradient'
                                : 'border border-[#F1F1F1] hover:bg-gray-600'
                                } transition-colors duration-300`}
                            onClick={() => setActiveStory(storyKey)}
                        >
                            {storyKey}
                        </button>
                    ))}
                </div>

                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeStory}
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -20 }}
                        transition={{ duration: 0.3 }}
                        className="md:flex items-center"
                    >
                        <img
                            src={stories[activeStory].image}
                            alt={stories[activeStory].title}
                            className="md:w-1/2 rounded-lg mr-8 mobile:mb-2"
                        />
                        <div>
                            <h3 className="text-2xl font-semibold mb-4">{stories[activeStory].title}</h3>
                            <p>{stories[activeStory].description}</p>
                        </div>
                    </motion.div>
                </AnimatePresence>

                <h2 className="text-2xl md:text-3xl font-bold mb-6 md:text-center mt-12 md:mt-24">Cloud Managed Services - Our Perspective</h2>

                <div className="grid grid-cols-1 md:grid-cols-3 my-12 mobile:mb-6 md:mb-12">
                    {cards.map((card, index) => (
                        <div key={index} className="bg-[#14192E] p-6 rounded-2xl md:w-11/12 mx-auto mobile:mb-4 mb-8">
                            <h3 className="text-xl font-semibold mb-4">{card.title}</h3>
                            <p className="mb-4">{card.description}</p>
                            <button className="text-theme hover:text-blue-300 transition-colors duration-300">
                                Read more <span className="ml-2">&raquo;</span>
                            </button>
                        </div>
                    ))}
                </div>

            </div>
        );
    };


    const FAQSection = () => {
        const faqs = [
            {
                question: "Why do enterprises need cloud managed services?",
                answer: `Cloud platform providers deliver the necessary infrastructure for your organization.
                However, cloud platforms harbor hundreds of native technologies, and it’s necessary to realize a tailored cloud architecture that would fit a particular business best, align with business objectives, and prevent failovers on the cloud.
                `
            },
            {
                question: "What is included in managed services?",
                answer: "Moving to SAP on Cloud offers benefits like scalability, cost-efficiency, and easier maintenance compared to on-premises solutions. Cloud solutions provide greater flexibility and can be accessed from anywhere, enhancing collaboration and productivity."
            },
            {
                question: "What are the benefits of managed IT services?",
                answer: "DIAA Corp's SAP Switch2Cloud is a customized migration solution, while SAP RISE Switch2Cloud is SAP's bundled offering. DIAA Corp's solution may offer more flexibility and tailored services, whereas SAP RISE provides an all-in-one package directly from SAP."
            },
        ];

        return (
            <div className="flex items-center justify-center p-4">
                <div className="max-w-3xl w-full">
                    <h1 className="text-2xl md:text-3xl font-bold text-white text-center mb-8 md:mb-16">
                        Cloud Managed Services - FAQs
                    </h1>
                    {faqs.map((faq, index) => (
                        <FAQItem key={index} question={faq.question} answer={faq.answer} />
                    ))}
                </div>
            </div>
        );
    };


    return (
        <div className="min-h-screen text-white">
            <Header {...headerData} isExploreBtn={true} backgroundImage={coverImage} />

            <div>
                <MigrationInfo {...migrationInfoData} isMigrationFactory={false} />
            </div>

            <div>
                <ContactContent title={`Adopt the Smart Cloud approach. Embrace an Intelligent tomorrow`} />
            </div>

            <div>
                <DIAACorpRecognition />
            </div>

            <div>
                <OrganizationsServed />
            </div>

            <div>
                <ClientTestimonials />
            </div>

            <div>
                <DIAACorpCloudComponent />
            </div>

            <div className='mt-8 md:mt-24'>
                <Slider tabs={tabs} content={content} isHeader={true} />
            </div>

            <div>
                <ContactContent2 title={`Ready To Embrace The New Potential Of Cloud ?`} sub={`Connect with our Cloud Experts`} />
            </div>

            <div>
                <CloudManagedTabs />

                <Slider tabs={tab1} content={content1} />
                <Slider tabs={tab2} content={content2} />
                <Slider tabs={tab3} content={content3} />
            </div>

            <div className='md:w-11/12 mx-auto'>
                <CloudDifference />
            </div>

            <div className='md:w-11/12 mx-auto'>
                <TechStack />
            </div>

            <div className='md:w-11/12 mx-auto'>
                <KnowledgeDeskManagedCloud />
            </div>

            <div className='md:w-11/12 mx-auto'>
                <FAQSection />
            </div>

            <div>
                <Footer />
            </div>
        </div>
    )
}

export default CloudManagedServices