import React from 'react'
import { Header } from '../components/resuable/SapSolution'
import sapConsultingCover from '../assets/SAP/sapConsultionCover.png'
import SapConsultingImg1 from '../assets/SAP/sapConsultionImg1.png'
import SapConsultingImg2 from '../assets/SAP/SapConsultingImg2.png'
import SapConsultingImg3 from '../assets/SAP/SapConsultingImg3.png'
import SapConsultingImg4 from '../assets/SAP/SapConsultingImg4.png'
import { ContactContent } from '../components/resuable/SapComponents'
import Footer from '../components/Footer'
import { useDocumentTitle } from '../utils/useDocumentTitle'

function SapConsulting() {
    const headerData = {
        title: "Proven Prowess In SAP Consulting",
        description: "DIAA Corp, with SAP solutions and our advanced industry expertise, will rapidly and flawlessly transform your business with digital innovation."
    };

    useDocumentTitle(headerData?.title.replace(/\b(what|is)\b/gi, ''));

    const SAPServiceOrg = () => {
        return (
            <div className="text-white p-8">
                <div className="container mx-auto flex flex-col lg:flex-row md:space-x-12">
                    <div className="lg:w-2/3 pr-8 ">
                        <h1 className="text-2xl md:text-3xl font-bold mb-4">DIAA CORP SAP Service Organization</h1>
                        <p className="mb-4 mobile:text-sm">
                            DIAA CORP offers classic business consulting as well as SAP Consulting.
                            Our SAP consultants mainly focus on <a href="#" className="text-theme">SAP architecture</a> and technology
                            consulting in addition to consulting on the implementation of standard
                            SAP software and <a href="#" className="text-theme">SAP Application Management</a>. We support projects
                            in all facets spanning the complete project life cycle.
                        </p>
                        <p className="mb-4 mobile:text-sm">
                            This consists of management and strategic SAP consulting, proof of
                            concepts via project management, requirement management,
                            implementation consulting, customizing, SAP programming,
                            implementation support, training, migration, Human Capital
                            Management, Supplier Relationship Management, SAP Net Weaver,
                            SAP Portal support including SAP Business Intelligence and SAP
                            Business Objects as well as <a href="/sap-s4hana" className="text-theme">S/4HANA and SAP HANA</a>.
                        </p>
                        <p className='mobile:text-sm'>
                            Our SAP specialists ensure the operation and further development of
                            our customers' productive SAP systems and SAP applications,
                            according to proven ITSM standards.
                        </p>
                    </div>
                    <div className="lg:w-1/3 mt-8 lg:mt-0">
                        <div className="relative h-80">
                            <img src={SapConsultingImg1} alt="Meeting" className="absolute top-0 right-0 w-3/4 h-3/4 object-cover rounded-lg shadow-lg" />
                            <img src={SapConsultingImg2} alt="Analysis" className="absolute bottom-0 left-0 w-3/4 h-3/4 object-cover rounded-lg shadow-lg" />
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    const SAPConsultingInfos = () => {
        const cards = [
            {
                title: "Robust IT Strategy Is The Key",
                content: "An effective IT strategy needs to focus on your business needs - especially in a world where digitalization is revolutionizing business models. Our SAP consultants place your business objectives at the forefront, going further than simple technical advice. Our SAP specialists help you develop a comprehensive IT roadmap and optimize your existing business processes to pave the way for a successful digital transformation.",
            },
            {
                title: "Power Of Dynamic Ideal SAP Architecture",
                content: "Apart from providing performance and availability, an ideally deployed SAP architecture also takes into account complexity, flexibility, scalability besides spare and energy requirements. Furthermore, modern processors now offer a good level of performance and this leads to more powerful hardware being available at lower costs. On this premise, the existing SAP landscape can be reproduced and operated at a considerably lower cost, in addition to high availability, disaster recovery and backup scenarios that can be implemented.",
            },
            {
                title: "End-to-end expertise leveraged by industries",
                content: "An effective IT strategy needs to focus on your business needs - especially in a world where digitalization is revolutionizing business models. Our SAP consultants place your business objectives at the forefront, going further than simple technical advice. Our SAP specialists help you develop a comprehensive IT roadmap and optimize your existing business processes to pave the way for a successful digital transformation.",
            },
            {
                title: "Modernization with the latest SAP applications",
                content: "Restructuring and remodeling of IT and business models are being forced by digitization and growth of data loads. CloudEQ with the latest SAP applications like S4/HANA and SAP HANA help enterprises overcome these challenges to enable faster processes, intuitive handling and better flexibility.",
            },
        ];



        return (
            <div className="text-white md:w-11/12 mx-auto mt-12 md:mt-24">
                <div className="mx-auto">
                    <h1 className="text-2xl md:text-4xl font-bold text-center mb-2 md:mb-6">SAP Consulting</h1>
                    <p className="text-center text-gray-400 mb-12 mobile:text-md">Approach to align your IT Strategy with business goals</p>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        {cards.map((card, index) => (
                            <div key={index} className="relative p-6 rounded-lg overflow-hidden md:w-4/5 mx-auto">
                                <div className="absolute inset-0 bg-custom-gradient opacity-80 rounded-lg"></div>
                                <div className="absolute inset-[1px] bg-bg rounded-lg"></div>
                                <div className="relative z-10">
                                    <h2 className="md:text-xl font-semibold mb-4">{card.title}</h2>
                                    <p className="text-sm text-gray-300 mb-4">{card.content}</p>
                                    <a href="#" className="text-theme text-sm flex items-center">
                                        Read more
                                        <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const DifferentiatingPillars = () => {
        return (
            <div className="relative md:h-screen w-full bg-cover bg-center flex items-center justify-center mt-12 md:mt-24 pt-12 md:pt-24">
                <div className='absolute top-0 left-0 w-full h-full'
                    style={{
                        background: `url(${SapConsultingImg4})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        filter: 'blur(2px)',
                    }}></div>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="relative z-10 text-white text-center md:w-4/5">
                    <h1 className="text-xl md:text-4xl font-bold mb-8">We are Proud to Serve 4000+ Organizations across 25 Nations</h1>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 my-12 md:my-24">
                        {[
                            { icon: '🏦', title: 'Expertise Covering Core Industries', subtitle: 'Our team of IT consultants comprises of experts in a wide variety of industries. Our SAP consultants understand any business process – multinational manufacturing business, telecom giants with massive subscriber base, medium size wholesale distributor or a leading healthcare start-up. With SAP Consulting, we have been able to develop our own industry-specific accelerators, frameworks and add-ons owing to our experience in varied projects. This has helped us master value delivery to SAP implementation projects and standard software.' },
                            { icon: '🚗', title: 'Complete Transparency', subtitle: 'We update you constantly on the usual quality checks, prototypes, and solution demonstrations. Our SAP consultants, wherever necessary, can direct you through change management too. This way, you always accurately know what is happening at various stages of the project - whether it’s a strategy phase, IT roadmap development, or go live. What’s more, our global presence ensures that you have close contact with our SAP consultants, who understand your local culture and any applicable country-specific requirements.' },
                        ].map((item, index) => (
                            <div key={index} className="bg-[#3C3C3C] bg-opacity-50 p-4 rounded-lg flex items-center md:py-12 md:px-12 w-11/12 mx-auto">
                                <div className="text-left">
                                    <h3 className="font-semibold text-lg md:text-xl mb-6 text-center">{item.title}</h3>
                                    <p className="text-sm md:text-center">{item.subtitle}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };




    const ArchitectureAndTechnology = () => {
        const services = [
            { icon: '🔄', text: 'Introduction of new and upgraded SAP systems (RISE - Trial)' },
            { icon: '🔍', text: 'SAP Consulting focused on technology' },
            { icon: '🖥️', text: 'Designing of complex SAP system landscapes' },
            { icon: '🌐', text: 'Virtualization of SAP system landscapes' },
            { icon: '🔁', text: 'Migration of SAP system landscapes (inc HANA, S/4HANA)' },
            { icon: '🔗', text: 'Integration of external systems' },
            { icon: '📊', text: 'Evaluation of existing SAP systems (inc SAP Security Audit)' },
        ];

        const partnerFeatures = [
            { icon: '🔧', text: 'Our experts are leaders in handling SAP workloads.' },
            { icon: '🌍', text: 'Serve 4000+ customers globally, including 65 of the Fortune 500 & 50 of the FT 500 Indian companies.' },
            { icon: '🤝', text: 'We are one of the largest partners handling 1800+ enterprises with SAP HANA on Cloud' },
            { icon: '📈', text: 'We manage SAP HANA production workloads of 450+ customers with up to 25 TB & Scale-out nodes up to 64 TB' },
            { icon: '🔄', text: 'We support the complete portfolio of SAP solutions and databases.' },
        ];

        return (
            <div className="w-full text-white mt-12 md:mt-24 mobile:px-2">
                <div className=" mx-auto">

                    <h1 className="text-2xl md:text-4xl font-bold text-center mb-2">Architecture & Technology</h1>
                    <p className="text-center text-gray-400 mb-12 mobile:text-sm">A bird's eye-view into our SAP services scope</p>

                    <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mb-12 md:w-11/12 mx-auto ">
                        {services.map((service, index) => (
                            <div key={index} className="flex flex-col items-center">
                                <div className="relative mobile:w-12 mobile:h-12 w-16 h-16 mb-8">
                                    <div className="absolute inset-0 bg-gradient-to-r from-[#a100ff] to-[#57CFFF] rounded-full opacity-80"></div>
                                    <div className="absolute inset-1 bg-[#0F1A42] rounded-full flex items-center justify-center md:text-2xl">
                                        {service.icon}
                                    </div>
                                </div>
                                <p className="text-center text-sm mb-4 md:mb-8">{service.text}</p>
                            </div>
                        ))}
                    </div>

                    <div className='flex flex-col justify-center items-center text-center  bg-[#14192E] h-[293px] px-4 my-12 md:my-24 border-x-4 border-x-theme'>
                        <div className='md:w-4/5'>
                            <p className="text-md md:text-xl font-normal mb-12">
                                DIAA Corp helps in the selection of tools based on business criteria such as TCO Optimization,
                                Design and Architecture consulting, License Optimization and Business Process Outsourcing. Even with complex SAP projects, our SAP consultants support you with their many years of project experience.
                            </p>
                        </div>
                    </div>

                    <div>

                        <h2 className="text-xl md:text-3xl font-bold text-center mb-4">An Experienced and Trusted SAP Partner</h2>
                        <p className="text-center text-gray-400 mb-12 mobile:text-md">
                            DIAA Corp is committed to providing a continuous, secure and effective environment
                            with end-to-end management for your SAP workloads.
                        </p>
                    </div>


                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6 md:w-11/12 mx-auto mt-12 md:mt-24">
                        {partnerFeatures.map((feature, index) => (
                            <div key={index} className="flex flex-col items-center">
                                <div className="relative w-16 h-16 mb-4">
                                    <div className="absolute inset-0 bg-gradient-to-r from-[#a100ff] to-[#57CFFF] rounded-full opacity-80"></div>
                                    <div className="absolute inset-1 bg-[#0F1A42] rounded-full flex items-center justify-center text-2xl">
                                        {feature.icon}
                                    </div>
                                </div>
                                <p className="text-center text-sm">{feature.text}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div >
        );
    };


    const ServiceOfferings = () => {
        const services = [
            {
                title: 'Implementation',
                description: 'Design and implementation using best practices and methodologies',
            },
            {
                title: 'Support',
                description: 'Continuous support, enhancement, monitoring and upgrade services',
            },
            {
                title: 'Custom Development',
                description: 'Custom development for Best Practices',
            },
            {
                title: 'Integration',
                description: 'Custom development for Best Practices',
            },
        ];

        return (
            <div className="py-12 sm:py-16 md:py-20 lg:py-24 flex items-center justify-center bg-[#14192E80] bg-opacity-50 mt-8 sm:mt-12 md:mt-16 lg:mt-24">
                <div className="container mx-auto px-4">
                    <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-white text-center mb-6 sm:mb-8 md:mb-10 lg:mb-12">
                        Other SAP Service Offerings
                    </h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 mt-8 sm:mt-12 md:mt-16 lg:mt-20 justify-items-center">
                        {services.map((service, index) => (
                            <div
                                key={index}
                                className="bg-[#51566E] bg-opacity-30 rounded-lg p-4 sm:p-6 flex flex-col justify-between w-full max-w-[278px] h-[180px] sm:h-[194px] text-center"
                            >
                                <div>
                                    <h3 className="text-lg sm:text-xl font-semibold text-white mb-2 sm:mb-4">{service.title}</h3>
                                    <p className="text-gray-300 mb-2 sm:mb-4 text-xs sm:text-sm">{service.description}</p>
                                </div>
                                <button className="text-theme hover:text-blue-300 transition-colors text-sm sm:text-base">
                                    Know more →
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };



    const WinningPath = () => {
        return (
            <div className=" flex items-center justify-center px-4 py-16 md:my-24">
                <div className="max-w-4xl text-center">
                    <h1 className="text-xl md:text-4xl lg:text-5xl font-bold text-white mb-6">
                        Winning path to Customer and Business Excellence with SAP
                    </h1>
                    <p className="text-gray-300 mb-8 text-sm md:text-base mt-6 md:mt-12">
                        You want to optimize your IT, but need support to get the project started? We can advise you on the start of your IT or SAP project. With extensive analysis of existing IT and process landscapes as well as requirements, feasibility studies and cost estimates, we provide a strong base for your project and help with decision-making.
                    </p>
                    <button className="w-56 md:w-72 py-4 px-4 text-sm md:text-base mx-auto hover:bg-custom-gradient" style={{
                        border: "2px solid #a100ff",
                        borderRadius: "9999px",
                        // background: "linear-gradient(90deg, #a100ff, #57CFFF)",
                        backgroundOrigin: "border-box",
                    }}>
                        Sign up for free workshop
                    </button>
                </div>
            </div>
        );
    };


    return (
        <div className="min-h-screen text-white">
            <Header {...headerData} isExploreBtn={true} backgroundImage={sapConsultingCover} />

            <div className='md:w-11/12 mt-12 md:mt-24'>
                <SAPServiceOrg />
            </div>

            <div>
                <SAPConsultingInfos />
            </div>

            <div>
                <DifferentiatingPillars />
            </div>

            <div>
                <ArchitectureAndTechnology />
            </div>

            <div>
                <ServiceOfferings />
            </div>

            <div>
                <WinningPath />
            </div>

            <div>
                <ContactContent title={`Experience our expertise before you embark upon any transformation journey`} />
            </div>

            <div>
                <Footer />
            </div>
        </div>
    )
}

export default SapConsulting