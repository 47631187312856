import React from 'react'
import { Header } from '../components/resuable/SapSolution'
import SapManagedCover from '../assets/SAP/SapMangedCover.png'

import SapConsultingImg1 from '../assets/SAP/sapConsultionImg1.png'
import SapConsultingImg2 from '../assets/SAP/SapConsultingImg2.png'
import SapConsultingImg3 from '../assets/SAP/SapConsultingImg3.png'
import SapManagedImg3 from '../assets/SAP/SapManagedImg2.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faClock, faExpand, faCircleUp, faPlay, faShield, faUserTie, faLock } from '@fortawesome/free-solid-svg-icons';
import { ContactContent, FeatureGrid } from '../components/resuable/SapComponents'
import Footer from '../components/Footer'
import { useDocumentTitle } from '../utils/useDocumentTitle'

function SapManagedServices() {
    const headerData = {
        title: "SAP Managed Services By DIAA CORP - Your SAP Landscape In Our Safe Hands",
        description: "Move and manage mission-critical business applications on robust cloud at highest uptime, performance agility, and utmost security and continuity."
    };

    useDocumentTitle(headerData?.title.replace(/\b(what|is)\b/gi, ''));

    const migrationInfoData = {
        stats: [
            { title: "$5600", subtitle: "The average cost for a single minute of downtime is around" },
            { title: "42 %", subtitle: "Application managed services boosts employee productivity" },
        ],
        mainContent: {
            heading: "Maximum Efficiency, Maximized Operations: Run your Enterprise Applications on a Robust Cloud Backend",
            paragraphs: [
                "Modern enterprises are heavily reliant on business applications to automate, augment, or modernize key operations such as workflow management, billing, project delivery, content management, supply chain control, data management, strategic governance, customer services, and more. However, as IT landscapes get more complicated each day, on-prem application management has become a major headache for businesses.",
                "With DIAA Corp, the world’s largest end-to-end application-focused cloud managed service provider, avail a transformative application managed service experience. Relieve your organization of all the application backend hassles including infra hosting, server management, networks, middleware, and platform complexities and leverage the complete prowess of your adopted application systems such as ERP, CRM, CMS, DBMS apps on cloud at maximum availability, security, and continuity. Evolve your organizational functionality beyond traditional IT boundaries with advanced analytics and intelligence.",
            ]
        },
    };

    const SAPServiceOrg = () => {
        return (
            <div className="text-white p-8 md:mb-24">
                <div className="container mx-auto flex flex-col lg:flex-row md:space-x-12">
                    <div className="lg:w-2/3 pr-8 ">
                        {/* <h1 className="text-3xl font-bold mb-4">DIAA CORP SAP Service Organization</h1> */}
                        <p className="mb-4 mobile:text-base">
                            Your business-critical applications need to run uninterrupted around-the-clock. To make your SAP applications work undisrupted, meet business-demands, and be future-proof, it is crucial to continually supervise and enhance SAP environments. Managing an SAP landscape can be challenging, time-consuming, and requires expert SAP skills. An SAP Managed Services Provider can help in streamlining your business processes, reduce Total Cost of Ownership (TCO), and enables you to focus on our business goals by freeing your teams from managing your SAP environment.
                        </p>
                        <p className="mb-4 mobile:text-base">
                            <a href="/" className="text-theme">DIAA CORP</a> is one of the largest and trusted SAP partners and is the world’s largest Automation driven managed services provider in high growth economies. Our unique pool of trained and certified experts understand the nuances of SAP and have expertise in handling SAP environments across various industries. We leverage our infrastructure and, application management expertise to create a fool-proof strategy for managing mission-critical business applications.
                        </p>
                    </div>
                    <div className="lg:w-1/3 mt-8 lg:mt-0">
                        <div className="relative h-80">
                            <img src={SapConsultingImg1} alt="Meeting" className="absolute top-0 right-0 w-3/4 h-3/4 object-cover rounded-lg shadow-lg" />
                            <img src={SapConsultingImg2} alt="Analysis" className="absolute bottom-0 left-0 w-3/4 h-3/4 object-cover rounded-lg shadow-lg" />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const DifferentiatingPillars = () => {
        return (
            <div className="relative w-full bg-cover bg-center flex items-center justify-center pt-12 md:pt-24">
                <div className="absolute top-0 left-0 w-full h-full"
                    style={{
                        background: `url(${SapManagedImg3})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        filter: 'blur(2px)',
                    }}></div>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="relative z-10 text-white text-center w-full px-4 md:w-4/5 mx-auto">
                    <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold mb-8">Key Benefits of SAP Managed Services</h1>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 my-8 md:my-16 lg:my-24">
                        {[
                            { icon: <FontAwesomeIcon icon={faCoins} />, title: 'Reduced TCO & Maintenance costs', subtitle: 'Minimize your total cost of ownership and maintenance expenses with our efficient SAP solutions.' },
                            { icon: <FontAwesomeIcon icon={faClock} />, title: 'Around-the-clock Support Services', subtitle: 'Get 24/7 support services in your regional language for uninterrupted SAP operations.' },
                            { icon: <FontAwesomeIcon icon={faExpand} />, title: 'Flexibility to Scale', subtitle: 'Easily scale your SAP solutions up or down to match your changing business needs.' },
                            { icon: <FontAwesomeIcon icon={faCircleUp} />, title: 'High Availability & Guaranteed uptime', subtitle: 'Ensure maximum uptime and availability of your SAP applications with our expert solutions.' },
                            { icon: <FontAwesomeIcon icon={faPlay} />, title: 'Business Continuity with Zero Disruptions', subtitle: 'Maintain uninterrupted business operations with our reliable SAP solutions and support.' },
                            { icon: <FontAwesomeIcon icon={faShield} />, title: 'Application & Database Security Audits', subtitle: 'Protect your SAP applications and databases with regular security audits and expert solutions.' },
                            { icon: <FontAwesomeIcon icon={faUserTie} />, title: 'Administration & Monitoring by Expert SAP Resources', subtitle: 'Get expert administration and monitoring of your SAP resources for optimal performance.' },
                            { icon: <FontAwesomeIcon icon={faLock} />, title: 'Enhanced Protection with 24*7 Security Services', subtitle: 'Enjoy enhanced protection of your SAP applications with our 24/7 security services.' },
                        ].map((item, index) => (
                            <div key={index} className="bg-[#52527E] bg-opacity-40 p-4 rounded-lg flex flex-col items-center h-full">
                                <span className='text-theme text-2xl mb-4'>
                                    {item.icon}
                                </span>
                                <div className="text-center">
                                    <h3 className="font-semibold text-sm sm:text-base md:text-lg mb-2">{item.title}</h3>
                                    <p className="text-xs sm:text-sm text-gray-300">{item.subtitle}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const SAPConsultingInfos = () => {
        const cards = [
            {
                title: "Managed Infrastructure, Migration & Provisioning",
                content: "We handle On-prem/Cloud infrastructure administration, datacenter administration, and migrations to the Cloud. We manage and migrate SAP workloads to all major cloud platforms like AWS, Azure, and GCP using industry best tools and leading practices.",
            },
            {
                title: "SAP New Implementation & Support",
                content: "We help identify new opportunities to meet emerging business needs. Our Certified experts create roadmaps for new implementations, deciding how to scale SAP in other business operations. We implement and support existing and new features/functionality.",
            },
            {
                title: "Monitoring and Audits",
                content: "We provide 24x7 operational support, proactively identify issues, and remediate them. We perform regular application and Database audits to ensure no vulnerabilities or non-compliances. Our automated/intelligent and Proactive monitoring makes your systems run accurately.",
            },
            {
                title: "Administration & Quality (Upgrades, Health checks)",
                content: "We take full ownership of Database administration, SAP Installations, Enhancements & Upgrades. We perform automated Health checks/SAP Quality checks and take care of incident and change management systems to ensure everything works as intended.",
            },
            {
                title: "SAP Security & Governance",
                content: "Our SAP security services include Vulnerability Assessments, Security strategies, data protection, and a robust governance model providing role-based access control. Our 24*7 Security Operation Center (SOC) provides enhanced protection for your SAP applications.",
            },
            {
                title: "Outage Handling & Business continuity",
                content: "We provide automated Backup and Disaster recovery services (DRaaS). We ensure near-zero-downtime and keep your business operations running at any given time. In the event of outages, we do an auto start of SAP applications, SAP HANA, Sybase ASE, MAX DB, etc.",
            },
        ];



        return (
            <div className="text-white md:w-11/12 mx-auto my-12 md:my-24 mobile:px-2">
                <div className="mx-auto">
                    <h1 className="text-xl md:text-4xl font-bold md:text-center mb-4 md:mb-6">DIAA CORP SAP Managed Services Offerings</h1>
                    <p className="mobile:text-sm md:text-center text-gray-400 mb-12 md:mb-24">At DIAA Corp, we provide reliable SAP managed services that assure high availability and disaster recovery capabilities, ensuring your SAP operations are running without interruptions. Be it infrastructure administration, Migration & Provisioning support, Cost optimization, Monitoring & Management, Reporting & Control, we support you with personalized services, delivery & operating models. Our dedicated team(s) takes care of your total environment and provide 24x7x365 assistance in managing your SAP applications.</p>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 space-y-4">
                        {cards.map((card, index) => (
                            <div key={index} className="relative p-6 rounded-lg overflow-hidden md:w-4/5 mx-auto shadow-[0_0_10px_#a100ff]">
                                <div className="absolute inset-0 bg-gradient-to-r from-[#a100ff] to-[#57CFFF] opacity-80 rounded-lg"></div>
                                <div className="absolute inset-[1px] bg-bg rounded-lg"></div>
                                <div className="relative z-10">
                                    <h2 className="text-lg md:text-xl font-semibold mb-4 text-center">{card.title}</h2>
                                    <p className="text-sm text-gray-300 mb-4">{card.content}</p>

                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };


    function SAPServicesComponent() {
        const services = [
            {
                title: "Intelligent Monitoring",
                points: [
                    "Health Check (ABAP, Sybase, HANA, JAVA &BO)",
                    "SAP Application License",
                    "SAP Hana Sitter"
                ],
                position: "top-[-5%] left-1/2 -translate-x-1/2"
            },
            {
                title: "Auto Reaction Method",
                points: ["Memory Alerts", "Disk Alerts", "CPU Alerts", "SAP HANA Replication"],
                position: "top-[10%] left-[10%]"
            },
            {
                title: "Administration",
                points: [
                    "SAP Kernel Upgrade",
                    "SAP Application Support",
                    "SAP Health Check",
                    "SAP License Check",
                    "SAP EWA Download",
                    "SAP HANA Upgrade",
                    "SAP Sybase Upgrade"
                ],
                position: "top-[10%] right-[10%]"
            },
            {
                title: "Proactive Monitoring",
                points: ["ELK Stack", "Log Analysis", "SAP HANA Cleaner", "SAP HANA Checker"],
                position: "top-[35%] left-[5%]"
            },
            {
                title: "Build Servers",
                points: ["SAP Application Builds", "SAP Quality Checks", "SAP Migration Platform"],
                position: "top-[40%] right-[5%]"
            },
            {
                title: "Reporting",
                points: [
                    "Capacity Planning",
                    "Montory Reports",
                    "Quality Reports",
                    "COE Manager's Live Dashboard"
                ],
                position: "bottom-[20%] left-[10%]"
            },
            {
                title: "Outage Handling",
                points: [
                    "Auto Start Of SAP Application",
                    "ABAP, JAVA, BO, Cloud Connector, Web Dispatcher",
                    "Auto SAP Of HANA, Sybase ASE, MAX DB"
                ],
                position: "bottom-[20%] right-[10%]"
            },
            {
                title: "Governance",
                points: [
                    "Live Dashboard For Events and Alert",
                    "PPM Activities",
                    "SIP Action Items"
                ],
                position: "bottom-[0%] left-1/2 -translate-x-1/2"
            },
        ];

        return (
            <div className="text-white p-4 md:p-8 flex items-center justify-center md:my-12 lg:my-24 xl:my-32">
                <div className="w-full max-w-7xl relative">
                    {/* Desktop view (only for screens wider than 1320px) */}
                    <div className="hidden xl:block relative aspect-[16/9]">
                        {services.map((service, index) => (
                            <div
                                key={index}
                                className={`absolute ${service.position} w-64 bg-[#14192E] rounded-lg p-4 flex flex-col items-start justify-start`}
                                style={{
                                    borderWidth: '2px',
                                    borderStyle: 'solid',
                                    borderImage: 'linear-gradient(90deg, #4F75FF -55%, #a100ff) 1',
                                }}
                            >
                                <h3 className="text-sm font-bold mb-2">{service.title}</h3>
                                <ul className="text-xs space-y-1">
                                    {service.points.map((point, idx) => (
                                        <li key={idx}>{point}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[30%] aspect-square">
                            <div className="absolute inset-0 bg-blue-900 rounded-full flex items-center justify-center">
                                <div className="text-2xl font-bold text-white text-center">SAP Services</div>
                            </div>
                        </div>
                    </div>

                    {/* Mobile and Tablet view (for screens smaller than 1320px) */}
                    <div className="xl:hidden flex flex-col items-center space-y-4">
                        <div className="bg-blue-900 rounded-full p-4 md:p-6 mb-4 md:mb-6">
                            <div className="text-xl md:text-2xl font-bold text-white">SAP Services</div>
                        </div>
                        {services.map((service, index) => (
                            <div
                                key={index}
                                className="bg-[#14192E] rounded-lg p-3 md:p-4 w-full max-w-md"
                                style={{
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    borderImage: 'linear-gradient(90deg, #4F75FF -55%, #a100ff) 1',
                                }}
                            >
                                <h3 className="text-sm md:text-base font-bold mb-2">{service.title}</h3>
                                <ul className="text-xs md:text-sm space-y-1">
                                    {service.points.map((point, idx) => (
                                        <li key={idx}>{point}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
    const ServiceOfferings = () => {
        const services = [
            {
                title: 'Telecom',
                description: 'DIAA CORP helps a Telecom Giant trim costs and embrace a Digital, Data Driven World with SAP HANA on a SAP certified community cloud',
            },
            {
                title: 'Medical Retail',
                description: 'A Leading Healthcare Retail Chain gets In-Country SAP Cloud Advantage with DIAA CORP',
            },
            {
                title: 'Oil & Gas',
                description: 'DIAA CROP hosts and manages mission critical SAP workloads on Azure for an oil and gas major in Middle East',
            },
        ];

        return (
            <div className="py-12 sm:py-16 md:py-20 lg:py-24 flex items-center justify-center bg-[#14192E80] bg-opacity-50 mt-8 sm:mt-12 md:mt-16 lg:mt-24">
                <div className="container mx-auto px-4">
                    <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-white text-center mb-6 sm:mb-8 md:mb-10 lg:mb-12">
                        Some of our Success Stories
                    </h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 mt-8 sm:mt-12 md:mt-16 lg:mt-20 justify-items-center">
                        {services.map((service, index) => (
                            <div
                                key={index}
                                className="bg-[#51566E] bg-opacity-30 rounded-lg p-4 sm:p-6 flex flex-col justify-between w-full max-w-[344px] h-[240px] sm:h-[260px] text-center"
                            >
                                <div>
                                    <h3 className="text-lg sm:text-xl font-semibold text-white mb-2 sm:mb-4">{service.title}</h3>
                                    <p className="text-gray-300 mb-2 sm:mb-4 text-xs sm:text-sm">{service.description}</p>
                                </div>
                                <button className="py-3 sm:py-4 px-4 text-xs sm:text-sm mx-auto hover:bg-custom-gradient" style={{
                                    border: "2px solid #a100ff",
                                    borderRadius: "9999px",
                                    backgroundOrigin: "border-box",
                                }}>
                                    Download Case Study
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const features = [
        {
            icon: "Experience",
            text: "Globally one of the oldest and largest SAP partners managing huge HANA workloads. 180+ Large Enterprise Customers, 50+ S/HANA Projects delivered"
        },
        {
            icon: "Value for Money",
            text: "Cost-effective and scalable solutions, pay as you go models, provide you the flexibility to scale as per your business needs"
        },
        {
            icon: "Expertise",
            text: "Highly experienced and certified SAP teams with proven prowess in SAP upgrades, implementations, maintenance, and support"
        },
        {
            icon: "Optimum Delivery",
            text: "Business models (Onsite - Nearshore - Offshore) models, perfectly aligned for customer's requirement"
        },
        {
            icon: "Agile & Consistent Delivery",
            text: "Time tested methodologies to implement SAP solutions and Automation of tasks throughout the project lifecycle to optimize performance"
        },
        {
            icon: "Centers of Excellence",
            text: "25 CoEs across Application Lifecycle Management across all cloud platforms (AWS, MS Azure, GCP, IBM, Alibaba) and Intelligent Migration"
        },
        {
            icon: "Global Presence",
            text: "25 countries, 52 locations, certified and skilled global resources"
        },
        {
            icon: "Intelligent Managed Services",
            text: "Immediate action for threat detection. Single SLA up to application login layer. Managed services up-to SAP Basis technical layer."
        },
        {
            icon: "Certified SAP Center of Excellence",
            text: "SAP COE supporting the entire SAP portfolio is certified in multiple services."
        },
    ];

    const ManagedServicesBoxes = () => {
        return (
            <FeatureGrid
                title="DIAA CORP Deep Expertise in SAP Managed Services"
                features={features}
                isIconPresent={false}
            />
        );
    };

    const certifications = [
        { title: 'SAP Certified', subtitle: 'in SAP HANA Operations Services' },
        { title: 'SAP Certified', subtitle: 'in Hosting Services' },
        { title: 'SAP Certified', subtitle: 'in Cloud Services' },
        { title: 'SAP Certified', subtitle: 'in DevOps' },
        { title: 'SAP Certified', subtitle: 'in Application Management Services' },
    ];

    const CertificationCard = ({ title, subtitle }) => (
        <div className="bg-opacity-10 bg-white p-6 rounded-2xl shadow-md h-[154px] text-center flex flex-col justify-center">
            <h3 className="text-xl md:text-2xl font-bold text-white mb-2">{title}</h3>
            <p className="text-base md:text-lg font-medium text-gray-300">{subtitle}</p>
        </div>
    );

    const Certifications = () => (
        <div className="py-12 md:py-24 flex flex-col items-center justify-center">
            <h2 className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-white mb-8 md:mb-24">SAP Certified Provider</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-4">
                {certifications.map((cert, index) => (
                    <CertificationCard key={index} title={cert.title} subtitle={cert.subtitle} />
                ))}
            </div>
        </div>
    );

    return (
        <div className="min-h-screen text-white">
            <Header {...headerData} isExploreBtn={true} backgroundImage={SapManagedCover} />

            <div className='md:w-11/12 mt-12 md:mt-24'>
                <SAPServiceOrg />
            </div>

            <div>
                <DifferentiatingPillars />
            </div>

            <div>
                <SAPConsultingInfos />
            </div>

            <div>
                <SAPServicesComponent />
            </div>

            <div>
                <ServiceOfferings />
            </div>

            <div>
                <ManagedServicesBoxes />
            </div>

            <div>
                <Certifications />
            </div>

            <div>
                <ContactContent title={`Experience SAP And Its Impact On Business Transformation`}/>
            </div>

            <div><Footer /></div>

        </div>
    )
}

export default SapManagedServices