import React, { useState } from 'react';

const MigrationInfo = ({ stats, mainContent, infoBoxText, sapWorkloadsContent, isMigrationFactory }) => {
    console.log(stats.length, "stats");
    const [isStatsLength, setIsStatsLength] = useState(stats.length); 
    return (
        <div className="bg-[#0D0D2B] text-white py-8">
            <div className="w-full md:w-11/12 mx-auto px-4 md:px-8 md:my-12">
                {/* Stats section */}
                <div className={`flex flex-col items-center md:flex-row ${isStatsLength == 3 ? 'md:justify-between' : 'md:justify-center md:space-x-12'} mb-12`}>
                    {stats.map((stat, index) => (
                        <div key={index} className="bg-gradient-to-r from-[#1D1F3F] to-[#070727] rounded-lg p-4 text-center w-64 mt-8 md:mt-0">
                            <h3 className="text-2xl font-bold mb-2">{stat.title}</h3>
                            <p className="text-sm text-blue-400">{stat.subtitle}</p>
                        </div>
                    ))}
                </div>

                {/* Main content */}
                <h2 className="text-xl md:text-2xl font-bold mb-6 text-center mt-24">{mainContent.heading}</h2>
                {mainContent.paragraphs.map((paragraph, index) => (
                    <p key={index} className="mb-4 text-left text-sm md:text-base">
                        {paragraph}
                    </p>
                ))}
            </div>

            {isMigrationFactory && <div className='bg-[#14192E] mt-24 py-24'>
                {/* SAP Workloads section */}
                <div className="rounded-lg p-6 mb-8 md:w-11/12 mx-auto px-8">
                    <h3 className="text-xl font-bold mb-4 text-center">{sapWorkloadsContent.heading}</h3>
                    <p className="mb-4">
                        {sapWorkloadsContent.description}
                    </p>
                </div>

                {/* Additional info boxes */}
                <div className="grid grid-cols-2 gap-6 md:w-11/12 mx-auto py-8">
                    {infoBoxText.map((content, index) => (
                        <div key={index} className="bg-gradient-to-r from-[#070727] to-[#14192E] rounded-lg p-6">
                            <p className="mobile:text-xs text-sm">
                                {content}
                            </p>
                        </div>
                    ))}
                </div>
            </div>}
        </div>
    );
};

export default MigrationInfo;
