import React from 'react';
import { motion } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import imageUrl from '../assets/Logo.png'

const ComingSoon = () => {
  return (
    <div className="bg-bg min-h-screen flex flex-col items-center justify-center text-white ">
      <motion.h1
        className="text-4xl md:text-6xl font-bold mb-8"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Coming Soon
      </motion.h1>

      <motion.div
        className="relative w-64 h-64"
        animate={{ rotate: 360 }}
        transition={{ duration: 10, repeat: Infinity, ease: "linear" }}
      >
        <motion.div
          className="absolute inset-0 rounded-full border-4 border-theme opacity-20"
          animate={{ scale: [1, 1.2, 1] }}
          transition={{ duration: 2, repeat: Infinity }}
        />
        <motion.div
          className="absolute inset-2 rounded-full border-4 border-theme opacity-40"
          animate={{ scale: [1, 1.1, 1] }}
          transition={{ duration: 2, delay: 0.2, repeat: Infinity }}
        />
        <motion.div
          className="absolute inset-4 rounded-full border-4 border-theme opacity-60"
          animate={{ scale: [1, 1.05, 1] }}
          transition={{ duration: 2, delay: 0.4, repeat: Infinity }}
        />
        <motion.div
          className="absolute inset-0 flex items-center justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          <span className="text-xl font-semibold bg-clip-text text-transparent bg-custom-gradient">
            <LazyLoadImage
              src={imageUrl}
              alt="Box"
              // effect="blur"
              className="object-cover w-16 transition-transform duration-300 hover:scale-105 hover:filter hover:blur-sm"
              // wrapperClassName="absolute inset-0"
            />
          </span>
        </motion.div>
      </motion.div>

      <motion.p
        className="mt-8 text-lg text-center max-w-md"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.7 }}
      >
        We're working hard to bring you something amazing. Stay tuned!
      </motion.p>
    </div>
  );
};

export default ComingSoon;