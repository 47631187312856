import React, { useState, useEffect, useRef } from 'react';

export const StatsCard = ({ value, label, customStyle }) => {
  const [count, setCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);
  const numericValue = parseInt(value.replace(/\D/g, ''), 10);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  useEffect(() => {
    let animationFrame;
    if (isVisible) {
      let startTime;
      const duration = 2000; // 2 seconds

      const animate = (timestamp) => {
        if (!startTime) startTime = timestamp;
        const progress = timestamp - startTime;
        const percentage = Math.min(progress / duration, 1);
        setCount(Math.floor(percentage * numericValue));

        if (progress < duration) {
          animationFrame = requestAnimationFrame(animate);
        }
      };

      animationFrame = requestAnimationFrame(animate);
    }

    return () => {
      if (animationFrame) {
        cancelAnimationFrame(animationFrame);
      }
    };
  }, [isVisible, numericValue]);

  return (
    <div ref={ref} className={`bg-[#070727] p-6 rounded-lg ${customStyle}`}>
      <p className="text-2xl md:text-4xl font-bold mb-2">
        {count}
        {value.includes('+') ? '+' : ''}
        {value.includes('%') ? '%' : ''}
      </p>
      <p className="text-sm text-gray-300">{label}</p>
    </div>
  );
};