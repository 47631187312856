import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion, useInView } from 'framer-motion';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';


const geoUrl = "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json";

const markers = [
    { name: 'USA', coordinates: [-100, 40], region: 'North America' },
    { name: 'LATAM', coordinates: [-60, -10], region: 'South America' },
    { name: 'Europe', coordinates: [15, 50], region: 'Europe' },
    { name: 'Middle East', coordinates: [45, 25], region: 'Middle East' },
    { name: 'India', coordinates: [80, 20], region: 'Asia' },
    { name: 'Australia', coordinates: [135, -25], region: 'Australia' },
];

export const LocationMap = () => {
    const [hoveredMarker, setHoveredMarker] = useState(null);
    const [animatingMarker, setAnimatingMarker] = useState(null);
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, amount: 0.2 });

    const handleMouseEnter = useCallback((markerName) => {
        setHoveredMarker(markerName);
        setAnimatingMarker(null);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setHoveredMarker(null);
    }, []);

    useEffect(() => {
        let index = 0;
        let timeoutId;

        const animate = () => {
            setAnimatingMarker(markers[index].name);
            timeoutId = setTimeout(() => {
                setAnimatingMarker(null);
                index = (index + 1) % markers.length;
                if (!hoveredMarker) {
                    timeoutId = setTimeout(animate, 1500);
                }
            }, 1500);
        };

        if (!hoveredMarker) {
            timeoutId = setTimeout(animate, 1500);
        }

        return () => clearTimeout(timeoutId);
    }, [hoveredMarker]);

    const Popup = React.memo(({ marker }) => (
        <g>
            <rect x="-50" y="-45" width="100" height="40" fill="white" rx="5" ry="5" />
            <text textAnchor="middle" y="-30" style={{ fill: "#000", fontSize: "12px", fontWeight: "bold" }}>{marker.region}</text>
            <text textAnchor="middle" y="-15" style={{ fill: "#000", fontSize: "10px" }}>{marker.name}</text>
        </g>
    ));


    const MapMarker = React.memo(({ marker, isHovered, isAnimating, onMouseEnter, onMouseLeave }) => (
        <Marker coordinates={marker.coordinates}>
            <g onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <circle
                    r={6}
                    fill="#a100ff"
                    opacity={0.8}
                    stroke={isHovered || isAnimating ? "white" : "transparent"}
                    strokeWidth={2}
                />
                <AnimatePresence>
                    {(isHovered || isAnimating) && (
                        <motion.g
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.8 }}
                            transition={{ duration: 0.2 }}
                        >
                            <Popup marker={marker} />
                        </motion.g>
                    )}
                </AnimatePresence>
            </g>
        </Marker>
    ));


    const memoizedMarkers = useMemo(() => markers.map((marker) => (
        <MapMarker
            key={marker.name}
            marker={marker}
            isHovered={hoveredMarker === marker.name}
            isAnimating={animatingMarker === marker.name}
            onMouseEnter={() => handleMouseEnter(marker.name)}
            onMouseLeave={handleMouseLeave}
        />
    )), [hoveredMarker, animatingMarker, handleMouseEnter, handleMouseLeave]);

    return (
        <div className="bg-bg text-white py-16" ref={ref}>
            <div className="mx-auto md:px-4 mobile:max-w-6xl md:w-3/4 2xl:w-2/3">
                <motion.h1
                    className="mobile:text-6xl text-8xl font-bold mb-2 text-center bg-gradient-to-b from-white to-transparent bg-clip-text text-transparent"
                    initial={{ opacity: 0, y: -20 }}
                    animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }}
                    transition={{ duration: 0.5 }}
                >
                    LOCATION
                </motion.h1>
                <motion.p
                    className="mobile:text-base text-xl mb-2 text-center"
                    initial={{ opacity: 0, y: -20 }}
                    animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                >
                    Global Footprint-In-country hosting and billing
                </motion.p>
                <motion.p
                    className="mobile:text-sm text-lg mb-8 text-center text-gray-400"
                    initial={{ opacity: 0, y: -20 }}
                    animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                >
                    USA | LATAM | Europe | Middle East | India
                </motion.p>
                <div className="w-full lg:h-[900px] mobile:h-[400]">
                    <ComposableMap
                        projection="geoMercator"
                        projectionConfig={{
                            scale: 140,
                            center: [0, 30]
                        }}
                    >
                        <Geographies geography={geoUrl}>
                            {({ geographies }) =>
                                geographies.map((geo) => (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        fill="#1E213A"
                                        stroke="#2A2E5A"
                                        strokeWidth={0.5}
                                    />
                                ))
                            }
                        </Geographies>
                        {memoizedMarkers}
                    </ComposableMap>
                </div>
            </div>
        </div>
    );
};


export default LocationMap