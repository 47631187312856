import React, { useState } from 'react'
import { Header } from '../components/resuable/SapSolution'
import coverImage from '../assets/SAP/migrationSapCover.png'
import MigrationFactory from '../components/SAP/MigrationFactoryDiagram';
import MigrationInfo from '../components/SAP/MigrantInfo';
import { AnimatePresence, motion } from 'framer-motion';
import MigrationFactoryBenefits from '../components/SAP/MigrationFactoryBenefits';
import LocationMap from '../components/SAP/Map';
import awsIcon from '../assets/SAP/sap-cloud-aws.webp'
import gcpIcon from '../assets/SAP/sap-cloud-gcp.webp'
import azureIcon from '../assets/SAP/sap-cloud-migration-azure.webp'
import successStoryImg from '../assets/SAP/foodImage.png'
import Footer from '../components/Footer';
import { ContactContent, FAQItem, FeatureGrid } from '../components/resuable/SapComponents';
import { useDocumentTitle } from '../utils/useDocumentTitle';

function SAPMigrationFactory() {
    const headerData = {
        title: "SAP Migration Factory",
        description: "Power your SAP Transformation on Cloud with an Advanced, Standardized Step-by-Step Procedure. Gain High Performance, Maximum Availability Outcomes at Zero Disruption."
    };

    useDocumentTitle(headerData?.title.replace(/\b(what|is)\b/gi, ''));

    const migrationInfoData = {
        stats: [
            { title: "No. 1", subtitle: "driver for S/4 HANA Migration" },
            { title: "55 %", subtitle: "S/4 HANA projects deploy a Greenfield approach" },
            { title: "77 %", subtitle: "SAP Customers will rely on a partner for their S/4 HANA Migration project" }
        ],
        mainContent: {
            heading: "Migrations hyper tuned for Efficiency and Automation",
            paragraphs: [
                "December 1, 1913 - quite an underrated day in common folklore. But, that day, perhaps the most impactful revolution after electricity was unleashed for the industrial world. Henry Ford, arguably the Father of commercial motor vehicles, first deployed his famous Assembly Line technique for the production of automotive vehicles. The average output time was reduced from 12 hours to roughly one.",
                "On the surface, this might seem non-contextual to the IT or cloud world. But assembly lines helped establish and inspire two key paradigms: breaking complex production processes into defined independent steps synchronized in wholesome coherence and automation of all repetitive processes within.",
                "Such diligence enabled constant standardized outputs irrespective of internal jitters and obviously, drastically reduce delivery timelines without impacting product qualities. Why can’t the Cloud IT universe tasked with hyper-complicated migrations, asset modernizations, management of multiple ecosystems, infra, platforms, apps, databases, workloads, and finally deliver ultra-innovative digital products and services operate in the same fashion?"
            ]
        },
        infoBoxText: [
            "An industry standardized, step-by-step approach augmented with innovative automation called SAP Migration Factory helps achieve this magic with complete compliance with organizational objectives and needs. Also, enterprises struggling with legacy ERP applications and business solutions can leverage the SAP S/4HANA ERP suite on a custom cloud architecture leveraging DIAA Corp’s incredible SAP centered offers: SAP Switch2Cloud, SAP RISE Switch2Cloud, etc. With DIAA Corp as an end-to-end partner, experience a super-agile yet cost-effective transition with the Migration Factory Approach and top it off with full-stack AIOps-powered managed offerings from infra till application layer, at a single SLA promising the highest availability throughout.",
            "Augment the entire experience with cutting-edge intelligent RPA solutions tailored to address and automate SAP and other workflows on the cloud. Leverage the unprecedented expertise of DIAA Corp’s dedicated Migration Factory Specialists from our SAP Center of Excellence (COE) to achieve the transformation at a surprising TCO. Deploy and avail the best SAP has to offer with DIAA Corp, leveraging the power of the intelligent cloud."
        ],
        sapWorkloadsContent: {
            heading: "Migration of SAP Workloads to Cloud via the Migration Factory Approach",
            description: "DIAA Corp’s unique Factory-based approach to large-scale, complicated cloud migration, modernization, and managed services enabled its rise to become the world’s leading application-focused cloud MSP today and the largest global premium supplier of SAP HANA Enterprise Cloud. Adept and SAP-certified in HANA Operations Services, Cloud Services, Hosting Services, Application Management Services, and DevOps, DIAA Corp streamlines transitions of on-prem SAP workloads, applications, and databases to tailored public-private-hybrid-multi cloud architectures of choice at highest uptimes, negligible disruption, and zero data loss."
        }
    };





    const infoCardItems = [
        {
            title: "250+",
            sub: "Sap Success Stories"
        },
        {
            title: "2000+TB",
            sub: "Managed HANA"
        },
        {
            title: "6000+",
            sub: "SAP Systems Supported"
        },
        {
            title: "1000+",
            sub: "Sap Production Workloads"
        },
    ]

    const InfoCard = ({ title, sub, isLengthy }) => (
        <div className={`flex flex-col ${title === '250+' ? 'bg-custom-gradient' : 'bg-[#191F38]'} m-2 sm:m-0 rounded-lg drop-shadow-xl w-40 h-40 text-center justify-center`}>
            <h2 className="text-2xl font-semibold mb-4">{title}</h2>
            <p className='text-xs'>{sub}</p>
        </div>
    );

    const SapCenterOfExcellence = () => {
        return (
            <div className="text-white p-8">
                <div className="text-center mb-16">
                    <h1 className="text-xl md:text-3xl font-bold">SAP Center of Excellence at DIAA Corp</h1>
                </div>
                <div className="space-y-4 mb-24">
                    <div className="bg-[#1A1F31] md:w-1/2 py-8 px-6 rounded-full text-center mx-auto text-sm md:text-lg">Business process Councelting</div>
                    <div className="bg-[#1A1F31] md:w-1/2 py-8 px-6 rounded-full text-center mx-auto text-sm md:text-lg">Application Consolidation and Harmonization Services</div>
                    <div className="bg-[#1A1F31] md:w-1/2 py-8 px-6 rounded-full text-center mx-auto text-sm md:text-lg">System Integration Services (Greenfield/Brownfield/Bluefield)</div>
                    <div className="bg-[#1A1F31] md:w-1/2 py-8 px-6 rounded-full text-center mx-auto text-sm md:text-lg">Application Development Services</div>
                    <div className="bg-[#1A1F31] md:w-1/2 py-8 px-6 rounded-full text-center mx-auto text-sm md:text-lg">SAP Technology Factory (Upgrades, Conversations, Migrations etc.)</div>
                </div>
                <div className="text-center mb-16">
                    <h2 className="text-2xl font-bold">SAP S/4HANA, Private Cloud Edition</h2>
                </div>
                <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-24">
                    <div className="bg-[#1A1F31] py-16 md:w-72 px-6 rounded-2xl text-center text-sm md:text-lg">DIAA Corp Private Cloud (CtrlS, Other Global DCs)</div>
                    <div className="bg-[#1A1F31] py-16 md:w-72 px-6 rounded-2xl text-center text-sm md:text-lg">Any Public Cloud (AWS, Azure, GCP, Alibaba)</div>
                    <div className="bg-[#1A1F31] py-16 md:w-72 px-6 rounded-2xl text-center text-sm md:text-lg">Any On-Premise Cloud (Customer's Data Center)</div>
                </div>
            </div>
        );
    };


    const SapServiceGrid = () => {
        const services = [
            'SAP Advisory and consulting service',
            'SAP Migration Services using Migration Factory',
            'SAP HEC, S/4 HANA, SAP Rise Conversations',
            'SAP Advisory and consulting service',
            'SAP Migration Services using Migration Factory',
            'SAP Migration Services using Migration Factory',
            'SAP Advisory and consulting service',
            'SAP Migration Services using Migration Factory',
            'SAP Migration Services using Migration Factory'
        ];

        return (
            <div className="bg-[#14192E] w-full lg:w-5/6 xl:w-4/6 text-white p-4 lg:p-6 xl:p-8 flex justify-center items-center rounded-2xl border-4/90 border-custom-gradient shadow-[0_0_20px_#a100ff]">
                <div className="relative rounded-lg shadow-lg p-4 lg:p-6 xl:p-8">
                    <div className="rounded-lg"></div>
                    <div className="relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-6 xl:gap-8 z-10">
                        {services.map((service, index) => (
                            <div key={index} className="bg-[#070727] p-3 lg:p-4 xl:p-6 h-24 lg:h-28 xl:h-32 text-xs lg:text-sm xl:text-base rounded-2xl text-center flex flex-col items-center justify-center">
                                {service}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };



    const SapMigrations = () => {
        return (
            <div className="bg-[#14192E] text-white p-8 flex justify-center items-center md:my-12 xl:my-24 3xl:my-48">
                <div className="max-w-6xl mx-auto">
                    <div className="flex flex-col md:flex-row justify-between items-center mb-8">
                        <div className="md:w-1/2 pr-4">
                            <h1 className="text-xl md:text-3xl font-bold mb-4">Fortifying the Foundations: SAP Migrations Designed and Implemented for Any Cloud, Any Architecture No Questions Asked</h1>
                            {/* <p>Modern businesses need to be flexible in synonymy to an ever-fluctuating market. As a result, firms are often equipped with multiple architectural challenges followed by frequent IT upgrade and renovation demands. Such agility could be best achieved by adopting digital-first operational models using SAP Business Suite and leveraging the latter’s true potential by hosting all SAP workloads, applications, databases on the cloud.</p> */}
                        </div>
                        <div className="md:w-1/2 md:pl-4 mt-8 md:mt-0">
                            <p>Modern businesses need to be flexible in synonymy to an ever-fluctuating market. As a result, firms are often equipped with multiple architectural challenges followed by frequent IT upgrade and renovation demands. Such agility could be best achieved by adopting digital-first operational models using SAP Business Suite and leveraging the latter’s true potential by hosting all SAP workloads, applications, databases on the cloud.</p>
                        </div>
                    </div>
                    <div className="md:text-center mb-8">
                        <p>Our SAP Migration Specialists and Cloud Engineers ensure that a business can seamlessly migrate to a cloud-powered SAP experience on any cloud platform of choice, regardless of operational ecosystem complexity and underlining compliance. DIAA Corp’s automation-rich, secure, and hyper-fast Migration Factory Approach is equipped to achieve the transition objective on the desired cloud platform at highest availability in a single SLA, from the infra till the application layer. In tandem, gain transformational impact from the IT till the business strata. Meet and exceed your enterprise digital transformation objectives on the cloud.</p>
                    </div>
                    <div className="flex justify-center space-x-4 mt-16 md:mt-24">
                        <div className="p-8 rounded-lg">
                            <img src={awsIcon} alt="Logo" className="mx-auto" />
                        </div>
                        <div className="p-8 rounded-lg">
                            <img src={azureIcon} alt="Logo" className="mx-auto" />
                        </div>
                        <div className="p-8 rounded-lg">
                            <img src={gcpIcon} alt="Logo" className="mx-auto" />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const MigrationFactory2 = () => {
        const steps = [
            {
                number: 1,
                title: 'Pre-Migration',
                items: ['Platform and Foundation Services']
            },
            {
                number: 2,
                title: 'Assessment and Planning',
                items: ['Migration Assessment and Planning', 'Pilot Migration']
            },
            {
                number: 3,
                title: 'Migration to Cloud',
                items: ['Optimize for customer outcomes', 'Engagement by leveraging intelligent Infrastructure']
            },
            {
                number: 4,
                title: 'Run on Cloud',
                items: ['Switch over to Cloud', 'Optimize Operations', 'Infrastructure & SAP Basis', 'Infrastructure & SAP Basis']
            },
            {
                number: 5,
                title: 'Transform',
                items: [
                    'Analyze and Interpret SAP Data through Machine Learning',
                    'Build Productive and Adaptive Apps for Intelligent Customer Engagement'
                ]
            }
        ];

        return (
            <div className="text-white p-8 mt-12">
                <h1 className="text-2xl md:text-3xl font-bold mb-4 text-center">Breaking down the Mist of DIAA Corp SAP Migration Factory</h1>
                <p className="text-sm mb-8 text-center max-w-3xl mx-auto text-[#F1F1F1] mobile:py-2">
                    DIAA Corp's SAP Migration Factory approach helps organizations standardize and accelerate their SAP infra, apps, workloads, database migrations, modernizations to the cloud. DIAA Corp. Deploying the highly efficient, intelligent automated assembly line approach, the migration is achieved super-fast at uncompromised security and continuity.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:4/5">
                    {steps.map((step) => (
                        <div key={step.number} className="bg-[#1B2032] rounded-2xl p-4 mt-2 md:mt-12 relative w-full md:w-72 mx-auto">
                            <div className="absolute -top-3 -left-3 bg-custom-gradient text-white text-xs font-bold px-2 py-1 rounded">
                                Step {step.number}
                            </div>
                            <h2 className="text-md font-semibold mb-2">{step.title}</h2>
                            <ul className="list-disc list-inside">
                                {step.items.map((item, index) => (
                                    <li key={index} className="text-xs text-gray-400">{item}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        );
    };


    const MigrationFactoryMap = () => {
        const migrations = [
            {
                icon: '⇄',
                title: 'Offline Migrations',
                points: [
                    'Downtime planned for database and marked as read-only',
                    'Migration done and applications switched to new databases'
                ]
            },
            {
                icon: '⚡',
                title: 'Online Migrations',
                points: [
                    'Minimal downtime',
                    'A front load of data to the target enabling sync so that the data continues to be replicated from source to target until the cut-over is ready to cut-over'
                ]
            },
            {
                icon: '🔄',
                title: 'Homogeneous Migrations',
                points: [
                    'Source and destination databases are same',
                    'Lift and Shift Approach',
                    'Database is installed on a VM on cloud, data from on-premise backup is restored followed by cut-over'
                ]
            },
            {
                icon: '🔀',
                title: 'Heterogeneous Migrations',
                points: [
                    'Source and destination databases are different',
                    'Additional assessment of the objects, schemas, stored procedures/functions, data types and code changes'
                ]
            },
            {
                icon: '🔁',
                title: 'Complex and Large-scale Migrations',
                points: [
                    'Complete data transfer to target database at one go',
                    'Many corner cases, new systems added to IT landscape'
                ]
            }
        ];

        return (
            <div className="text-white p-8 ">
                <h1 className="text-2xl md:text-3xl font-bold mb-4 md:mb-8 text-center my-12">
                    SAP Database Migration Factory Map: Any Ecosystem, Any Complexity
                </h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {migrations.map((migration, index) => (
                        <div key={index} className="relative p-[1px] rounded-lg bg-custom-gradient mobile:mt-12 md:mt-16">
                            <div className="bg-bg rounded-lg p-6 h-full flex flex-col px-16">
                                <div className="text-4xl mb-4 self-center">{migration.icon}</div>
                                <h2 className="text-xl font-semibold mb-4 text-center">{migration.title}</h2>
                                <ul className="list-none space-y-2 flex-grow">
                                    {migration.points.map((point, idx) => (
                                        <li key={idx} className="flex items-start">
                                            <span className="inline-block w-2 h-2 rounded-full bg-gradient-to-r from-[#a100ff] to-[#57CFFF] mr-2 mt-1.5 flex-shrink-0"></span>
                                            <span className="text-sm text-gray-300">{point}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const SAPCloudEvolution = () => {
        const switchCloudData = [
            {
                title: "SAP S/4HANA Switch2Cloud",
                included: [
                    "S/4HANA Transformation",
                    "SAP Managed Services",
                    "Application Management Services"
                ],
                benefits: [
                    "Reduced TCO",
                    "Single Stop Management with proprietary Self-healing operations Platform",
                    "AIOps-powered Managed Services",
                    "Non-SAP Workloads management"
                ]
            },
            {
                title: "SAP RISE Switch2Cloud",
                included: [
                    "SAP RISE Deployment and Managed Services",
                    "S/4HANA Migration",
                    "SAP Application Management Services"
                ],
                benefits: [
                    "Reduced TCO",
                    "Unified Delivery metrics and Visibility",
                    "Automation Solutions and Services",
                    "Cloud-agnostic Architecture design"
                ]
            }
        ];

        return (
            <div className=" text-white p-8 mt-12 md:mt-24">
                <h1 className="text-2xl md:text-3xl font-bold mb-4 text-center">
                    Inspire an Intelligent SAP on Cloud Evolution
                </h1>
                <p className="text-sm mb-12 text-left mx-auto">
                    With SAP S/4HANA, SAP took a transformative route in 2015. And, that was to take a fully cloud-first strategy to
                    deploy their upcoming innovations. SAP S/4HANA, written in cognitively-intense to the smart HANA in-memory
                    database and its private cloud version for all SAP applications (HANA Enterprise Cloud), is the ideal ERP
                    solution and an ignition for businesses to make their evolutions on the cloud. DIAA Corp, a leading global
                    supplier of SAP S/4HANA and HEC, realizes that achieving such transformation is easier said than done.
                    DIAA Corp's unique SAP Switch2Cloud offer allows enterprises to make swift conversions of their existing ECC
                    or legacy ERP workloads to S/4HANA, deploy and manage effectively on cloud with end-to-end application
                    management under a single, cost-effective SLA. The package could be customed based on client
                    requirements; greenfield, brownfield, or hybrid deployment models.
                </p>

                {switchCloudData.map((data, index) => (
                    <div key={index} className="mb-12">
                        <h2 className="text-2xl font-semibold mb-6 text-center mt-12 md:mt-24">{data.title}</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto mt-6 md:mt-12">
                            <div className="relative">
                                <div className="absolute inset-0 bg-gradient-to-br from-[#a100ff] to-[#57CFFF] opacity-50 rounded-lg  -left-1 -bottom-1"></div>
                                <div className="relative bg-[#14192E] p-6 rounded-lg h-full">
                                    <h3 className="text-xl font-semibold mb-4">What's Included</h3>
                                    <ul className="space-y-2">
                                        {data.included.map((item, idx) => (
                                            <li key={idx} className="flex items-start">
                                                <span className="text-blue-400 mr-2">•</span>
                                                <span>{item}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="relative">
                                <div className="absolute inset-0 bg-custom-gradient opacity-50 rounded-lg -left-1 -bottom-1"></div>
                                <div className="relative bg-[#14192E] p-6 rounded-lg h-full">
                                    <h3 className="text-xl font-semibold mb-4">Benefits</h3>
                                    <ul className="space-y-2">
                                        {data.benefits.map((item, idx) => (
                                            <li key={idx} className="flex items-start">
                                                <span className="text-blue-400 mr-2">•</span>
                                                <span>{item}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };


    const SAPSecurityAssessment = () => {
        const hexagonItems = [
            { text: 'Identify', position: 'col-start-3 row-start-1' },
            { text: 'Analyse', position: 'col-start-2 row-start-2' },
            { text: 'Plan', position: 'col-start-4 row-start-2' },
            { text: 'Track', position: 'col-start-1 row-start-3' },
            { text: 'Control', position: 'col-start-5 row-start-3' },
        ];

        return (
            <div className="text-white p-8 mt-12 md:mt-24">
                <h1 className="text-xl md:text-3xl font-bold text-center max-w-4xl mx-auto">
                    Securing the SAP Environment on
                </h1>
                <p className='text-xl md:text-3xl font-bold mb-12 text-center max-w-4xl mx-auto'>Cloud: Continuous Monitoring and Risk Assessment</p>

                <div className="relative max-w-3xl mx-auto mt-16">
                    {/* Central "Communicate" text */}
                    <div className="absolute inset-0 flex items-center justify-center z-10">
                        <span className="text-theme font-semibold text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl">Communicate</span>
                    </div>

                    {/* Hexagon grid */}
                    <div className="grid grid-cols-5 grid-rows-3 gap-4">
                        {hexagonItems.map((item, index) => (
                            <div key={index} className={`${item.position} flex items-center justify-center`}>
                                <div className="relative w-24 h-28 sm:w-28 sm:h-32 md:w-32 md:h-36">
                                    <svg viewBox="0 0 100 100" className="w-full h-full">
                                        <polygon
                                            points="50 1 95 25 95 75 50 99 5 75 5 25"
                                            fill="transparent"
                                            stroke="url(#custom-gradient)"
                                            strokeWidth="1"
                                        />
                                        <text x="50" y="50" dominantBaseline="middle" textAnchor="middle" fill="white" fontSize="14">
                                            {item.text}
                                        </text>
                                        <defs>
                                            <linearGradient id="custom-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                                <stop offset="0%" stopColor="#4F75FF" />
                                                <stop offset="100%" stopColor="#a100ff" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };




    const KnowledgeDesk = () => {
        const [activeStory, setActiveStory] = useState('Falconpack');

        const cards = [
            {
                title: 'Building an Innovation-first organization with SAP on AWS',
                description: 'Explore how to deploy SAP architecture on AWS cloud to build a scalable, agile enterprise for relentless production at full disruption.',
            },
            {
                title: 'SAP Application Modernization on Cloud',
                description: 'Explore the nuances of modifying, modernizing SAP applications on the cloud. Learn how to achieve the same fail-proof.',
            },
            {
                title: 'SAP Apps Migration to Microsoft Azure',
                description: 'Decode the Azure destination for your mission-critical SAP workloads and applications. Learn best practices and KPIs.',
            },
            // Add other cards here
        ];

        const stories = {
            Falconpack: {
                title: 'Falconpack',
                description: 'Global Food Packaging Manufacturer leverages customized SAP HANA Architecture on AWS to achieve breakthrough operational efficiency with 99.95% Uptime.',
                image: successStoryImg,
            },
            'Oil and Gas major': {
                title: 'Oil and Gas major',
                description: 'Global Food Packaging Manufacturer leverages customized SAP HANA Architecture on AWS to achieve breakthrough operational efficiency with 99.95% Uptime.',
                image: successStoryImg,
            },
            'Global Ceramics Brand': {
                title: 'Global Ceramics Brand',
                description: 'Global Food Packaging Manufacturer leverages customized SAP HANA Architecture on AWS to achieve breakthrough operational efficiency with 99.95% Uptime.',
                image: successStoryImg,
            },
            'Jaleel Holdings': {
                title: 'Jaleel Holdings',
                description: 'Global Food Packaging Manufacturer leverages customized SAP HANA Architecture on AWS to achieve breakthrough operational efficiency with 99.95% Uptime.',
                image: successStoryImg,
            },
            // Add other stories here
        };

        return (
            <div className="text-white mobile:px-4 md:p-8 mt-12 md:mt-24">
                <h2 className="text-xl md:text-3xl font-bold mb-6 text-center">From our Knowledge Desk</h2>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 my-12 px-4 sm:px-6">
                    {cards.map((card, index) => (
                        <div key={index} className="bg-[#14192E] p-6 rounded-2xl w-full">
                            <h3 className="text-base sm:text-lg lg:text-xl font-semibold mb-4">{card.title}</h3>
                            <p className="mb-4 text-sm sm:text-base">{card.description}</p>
                            <button className="text-theme hover:text-blue-300 transition-colors duration-300 text-sm sm:text-base">
                                Read more <span className="ml-2">&raquo;</span>
                            </button>
                        </div>
                    ))}
                </div>

                <h2 className="text-3xl font-bold mb-6 text-center md:mt-36">Our Success Stories</h2>

                <div className="flex flex-wrap gap-4 mb-8 justify-center mt-12 md:my-12 md:space-x-8">
                    {Object.keys(stories).map((storyKey) => (
                        <button
                            key={storyKey}
                            className={`px-4 md:px-6 py-2 rounded-full text-[#F1F1F1] ${activeStory === storyKey
                                ? 'bg-custom-gradient'
                                : 'border border-[#F1F1F1] hover:bg-gray-600'
                                } transition-colors duration-300`}
                            onClick={() => setActiveStory(storyKey)}
                        >
                            {storyKey}
                        </button>
                    ))}
                </div>

                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeStory}
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -20 }}
                        transition={{ duration: 0.3 }}
                        className="md:flex items-center"
                    >
                        <img
                            src={stories[activeStory].image}
                            alt={stories[activeStory].title}
                            className="md:w-1/2 rounded-lg md:mr-8 mobile mb-2"
                        />
                        <div>
                            <h3 className="text-2xl font-semibold mb-4">{stories[activeStory].title}</h3>
                            <p>{stories[activeStory].description}</p>
                        </div>
                    </motion.div>
                </AnimatePresence>
            </div>
        );
    };



    const features = [
        { icon: '🔗', text: "World's leading application focused Cloud MSP" },
        { icon: '📄', text: "Dedicated SAP migration, modernization, management, and managed services across clouds and major hyperscalers like AWS, Azure, Google, OCI, etc." },
        { icon: '🌍', text: "Largest premium global supplier of SAP RISE, hosted global supplier of SAP S/4HANA, RISE with SAP, etc." },
        { icon: '🔒', text: "SAP Certified in Application Management Services, S/4HANA Operations Services, Cloud Services" },
        { icon: '👥', text: "250+ SAP consultants and 2000+ certified cloud experts" },
        { icon: '💻', text: "Managed 15000+ SAP Instances and 2500+ TB of HANA Database" },
        { icon: '🚀', text: "Deployed 565 successful SAP projects across 30 countries" },
        { icon: '📈', text: "Industry highest SLA of 99.9% up to Application login layer" },
        { icon: '📊', text: "Global premium supplier for SAP RISE, Enterprise Cloud RISE, RISE with SAP" },
        { icon: '🔄', text: "1000+ SAP Migrations, 750+ SAP Implementations, 500+ SAP upgrades" },
        { icon: '🖥️', text: "6000+ SAP System refreshes performed, 50% reduction in workloads, 50+ ThirdParty Integrations" },
        { icon: '🛠️', text: "Offering support for complete suite SAP Applications and Databases" },
        { icon: '📊', text: "Leaders in managing SAP HANA workloads with 2019 peta bytes and extra scale out" },
        { icon: '☁️', text: "Dedicated SAP on AWS CoE with AWS-powered managed services" },
        { icon: '🔐', text: "Geography/Industry-specific compliance services with AWS marketplace and DIAA Corp Managed security offerings" },
        { icon: '🏆', text: "SAP App Tech certified offerings for SAP GAMA, NISTC M&S, CGMP, HIPAA, and more" },
    ];
    const ImpactDifference = () => {
        return (
            <FeatureGrid
                title="An Impact with Difference: Why Trust DIAA Corp for your SAP Migration to the Cloud?"
                features={features}
            />
        );
    };



    const FAQSection = () => {
        const faqs = [
            {
                question: "What do you mean by the SAP Migration Factory Approach?",
                answer: "DIAA Corp's SAP Migration Factory approach involves an automation-powered, step by step process to transition existing SAP workloads to the cloud. Often including additional application modernization, database modernization, or ECC to SAP S/4HANA conversions while moving to cloud, everything is achieved in a streamlined assembly line-inspired process for maximum availability, agility, and friction-less outcomes."
            },
            {
                question: "Why should I move to SAP on Cloud rather than the on-prem version?",
                answer: "Moving to SAP on Cloud offers benefits like scalability, cost-efficiency, and easier maintenance compared to on-premises solutions. Cloud solutions provide greater flexibility and can be accessed from anywhere, enhancing collaboration and productivity."
            },
            {
                question: "What is the difference between DIAA Corp's SAP Switch2Cloud and SAP RISE Switch2Cloud Offers?",
                answer: "DIAA Corp's SAP Switch2Cloud is a customized migration solution, while SAP RISE Switch2Cloud is SAP's bundled offering. DIAA Corp's solution may offer more flexibility and tailored services, whereas SAP RISE provides an all-in-one package directly from SAP."
            },
        ];

        return (
            <div className="flex items-center justify-center p-4">
                <div className="max-w-3xl w-full">
                    <h1 className="text-xl md:text-3xl font-bold text-white text-center mb-8 md:mb-16">
                        SAP Migration Factory - FAQs
                    </h1>
                    {faqs.map((faq, index) => (
                        <FAQItem key={index} question={faq.question} answer={faq.answer} />
                    ))}
                </div>
            </div>
        );
    };


    return (
        <div className="min-h-screen text-white">
            <Header {...headerData} isExploreBtn={true} backgroundImage={coverImage} />

            <div>
                <MigrationInfo {...migrationInfoData} isMigrationFactory={true} />
            </div>

            <div className="container mx-auto md:px-4 py-16">
                <MigrationFactory />
            </div>

            <div>
                <ContactContent title={`Begin your SAP transformation journey with DIAA Corp. Connect with us today.`} />
            </div>

            <div>
                <MigrationFactoryBenefits />
            </div>

            <div>
                <ContactContent title={`Evolve your SAP Workflows on the Cloud at Zero Loss. Connect with us for a custom Discovery Workshop`} />
            </div>

            <div className='flex flex-col justify-center items-center text-center mt-12 sm:mt-24 bg-[#14192E] min-h-[200px] sm:h-[293px] px-4 sm:px-8 border-l-4 border-l-theme'>
                <div className='w-full sm:w-11/12'>
                    <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 sm:mb-6">Largest Global Premier Supplier</h2>
                    <p className="text-sm sm:text-base">for 180+ Enterprises of SAP HEC</p>
                </div>
            </div>

            <div className="relative z-10 mt-8 sm:-mt-20 container mx-auto px-4">
                <div className="flex flex-wrap justify-center items-center gap-4 sm:gap-6">
                    {infoCardItems.map((items, index) => (
                        <InfoCard key={index} title={items.title} sub={items.sub} />
                    ))}
                </div>
            </div>

            <div className='mx-auto md:px-4 py-16'>
                <SapCenterOfExcellence />
            </div>

            <div className='mx-auto md:px-4 md:py-16 flex flex-col justify-center items-center '>
                <SapServiceGrid />
            </div>

            <div className='mx-auto 4xl:py-16'>
                <LocationMap />
            </div>

            <div className='mx-auto  4xl:py-16'>
                <SapMigrations />
            </div>

            <div className='mx-auto md:w-11/12 4xl:py-16'>
                <MigrationFactory2 />
            </div>

            <div className='mx-auto md:w-11/12 4xl:py-16'>
                <MigrationFactoryMap />
            </div>

            <div className='mx-auto md:w-11/12 4xl:py-16'>
                <SAPCloudEvolution />
            </div>

            <div>
                <ContactContent title={`Let DIAA Corp charter a unique SAP evolution template on the cloud for your business. Connect with us today.`} />
            </div>

            <div className='mx-auto md:w-11/12 4xl:py-16'>
                <SAPSecurityAssessment />
            </div>

            <div className='mx-auto md:w-11/12 4xl:py-16'>
                <KnowledgeDesk />
            </div>

            <div className='mx-auto md:w-11/12 4xl:py-16'>
                <ImpactDifference />
            </div>

            <div className='mx-auto mt-12 md:mt-24'>
                <ContactContent title={`Sign up for a Discovery workshop to assess cloud maturity of your existing SAP workloads. Charter a fail-proof SAP transformation blueprint on the cloud.`} />
            </div>

            <div className='mx-auto mt-12 md:mt-24'>
                <FAQSection />
            </div>

            <div>
                <Footer />
            </div>

        </div>
    )
}

export default SAPMigrationFactory