import AppRouter from './pages/AppRouter';

function App() {
  return (
    <div className="bg-bg min-h-screen font-sans">
      <AppRouter />
    </div>
  );
}

export default App;
