import React, { useEffect, useState } from 'react'
import laptop from '../assets/SapS4hana/macbookSkeleton.png'; // Ensure you have a high-quality laptop image
import image1 from '../assets/SapS4hana/loio92c4b32791cf432ab5693d65879a39bc_LowRes.png'; // Replace with your actual image paths
import image2 from '../assets/SapS4hana/sap-s4hana-dashboard-screenshot.png';
import image3 from '../assets/SapS4hana/Customers-Overview-2-1.png';
import logo1 from '../assets/SapS4hana/logo1.png';
import logo2 from '../assets/SapS4hana/logo-1-dummy.png';
import logo3 from '../assets/SapS4hana/logo-2-dummy.png';
import logo4 from '../assets/SapS4hana/logo-3-dummy.png';
import imageCover from '../assets/SapS4hana/SAP-solutions-cover.png';
// import Footer from '../components/Footer';

// pages/SapS4hanaDemo.js
import { Header } from '../components/resuable/SapSolution';
import { InfoCards } from '../components/resuable/SapSolution';
import { Gallery } from '../components/resuable/SapSolution';
import { PaymentCards } from '../components/resuable/SapSolution';
import { BenefitsSection } from '../components/resuable/SapSolution';
import { PartnerLogos } from '../components/resuable/SapSolution';
import { FooterComponent } from '../components/resuable/SapSolution';
import { useDocumentTitle } from '../utils/useDocumentTitle';

const SapS4hana = () => {
    const headerData = {
        title: "What Is SAP S/4 Hana",
        description: "SAP S/4 HANA is a future-ready ERP System with embedded AI and machine learning covering all lines of business from Finance, Supply Chain, and Manufacturing, to Sales and more."
    };

    useDocumentTitle(headerData?.title.replace(/\b(what|is)\b/gi, ''));

    const infoCardsData = [
        {
            title: "SAP S/4 HANA Industries :",
            items: ['Energy & Natural Resources', 'Government Sector', 'Wholesale & Distribution', 'Retail & Restaurants', 'EC&O', 'Professional Service']
        },
        {
            title: "SAP S/4 HANA Availability:",
            items: ['On Prime', 'Cloud'],
            icon: 'M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z'
        },
        {
            title: "SAP S/4 HANA Employees:",
            items: ['Enterprise'],
            icon: 'M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z'
        }
    ];

    const galleryData = {
        images: [image1, image2, image3],
        description: "Explore the features and functionalities of SAP S/4 HANA through an interactive gallery."
    };

    const benefitsData = {
        title: "SAP S/4 HANA Benefits",
        description: "The platform provides instant real time, Architecture, Productivity, performance",
        benefits: [
            {
                icon: '⏱️',
                title: 'INSTANT, REAL-TIME',
                description: 'SAP S/4HANA provides Instant, real-time insight for better decisions with reduced maintenance costs, leading to increased productivity and efficiency with real-time data analytics.'
            },
            {
                icon: '📊',
                title: 'HIGHER PRODUCTIVITY',
                description: 'Add value to any business user in any line of business with the most user-friendly, role-based user experience.'
            },
            {
                icon: '📄',
                title: 'SIMPLIFIED ARCHITECTURE',
                description: 'SAP S/4 HANA can reduce your total cost of ownership by serving a single copy of data throughout all transactions in a flexible data model and structure.'
            },
            {
                icon: '📈',
                title: 'HIGHER PERFORMANCE',
                description: 'SAP S/4HANA gives you greater performance and responsiveness in every aspect of your business with native integration into SAP Cloud solutions like SuccessFactors and Ariba.'
            },
        ]
    };

    const partnerLogosData = {
        title: "SAP S/4 HANA Partners",
        logos: [logo1, logo2, logo3, logo4, logo1]
    };

    return (
        <div className="min-h-screen text-white">
            <Header {...headerData} backgroundImage={imageCover}/>
            <InfoCards cards={infoCardsData} />
            <Gallery {...galleryData} noLaptop={false} />
            <PaymentCards title={`SAP S/4 HANA Payment Models`} />
            <BenefitsSection {...benefitsData} />
            <PartnerLogos {...partnerLogosData} />
            <FooterComponent />
        </div>
    );
};

export default SapS4hana;