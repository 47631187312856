// hooks/useDocumentTitle.js
import { useEffect } from 'react';

export function useDocumentTitle(title) {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = `${title} | DIAA CORP`;
    return () => {
      document.title = prevTitle;
    };
  }, [title]);
}