import React, { useEffect, useState } from 'react'
import { Header } from '../components/resuable/SapSolution'
import MigrationInfo from '../components/SAP/MigrantInfo'
import EnterpriseCover from '../assets/SAP/EnterpriseMangedCover.png'
import cloudImg1 from '../assets/SAP/cloudManagedimg.png'
import mysqlImg1 from '../assets/SAP/mysql.png'
import gcpImg from '../assets/SAP/sap-cloud-gcp.webp';
import awsImg from '../assets/SAP/sap-cloud-aws.webp';
import azureImg from '../assets/SAP/sap-cloud-migration-azure.webp';
// Import more images as needed
import ourSuccessImg3 from '../assets/SAP/ourSuccessImg3.png'
import { motion, AnimatePresence } from 'framer-motion'
import { ContactContent, FAQItem, FeatureGrid, Slider } from '../components/resuable/SapComponents'
import LocationMap from '../components/SAP/Map'
import { FaGlobe, FaCloud, FaLock, FaClock, FaGraph, FaRobot, FaDatabase, FaChartBar } from 'react-icons/fa';
import Footer from '../components/Footer'
import { useDocumentTitle } from '../utils/useDocumentTitle'

const images = [
    { src: mysqlImg1, alt: "MySQL" },
    { src: gcpImg, alt: "GcpImg" },
    { src: awsImg, alt: "awsImg" },
    { src: azureImg, alt: "azureImg" },
    // Add more image objects as needed
];

function EnterpriseApplications() {

    
    const headerData = {
        title: "Enterprise Application Managed Services",
        description: "Move and manage mission-critical business applications on robust cloud at highest uptime, performance agility, and utmost security and continuity."
    };

    useDocumentTitle(headerData?.title.replace(/\b(what|is)\b/gi, ''));
    
    const migrationInfoData = {
        stats: [
            { title: "$5600", subtitle: "The average cost for a single minute of downtime is around" },
            { title: "42 %", subtitle: "Application managed services boosts employee productivity" },
        ],
        mainContent: {
            heading: "Maximum Efficiency, Maximized Operations: Run your Enterprise Applications on a Robust Cloud Backend",
            paragraphs: [
                "Modern enterprises are heavily reliant on business applications to automate, augment, or modernize key operations such as workflow management, billing, project delivery, content management, supply chain control, data management, strategic governance, customer services, and more. However, as IT landscapes get more complicated each day, on-prem application management has become a major headache for businesses.",
                "With DIAA Corp, the world’s largest end-to-end application-focused cloud managed service provider, avail a transformative application managed service experience. Relieve your organization of all the application backend hassles including infra hosting, server management, networks, middleware, and platform complexities and leverage the complete prowess of your adopted application systems such as ERP, CRM, CMS, DBMS apps on cloud at maximum availability, security, and continuity. Evolve your organizational functionality beyond traditional IT boundaries with advanced analytics and intelligence.",
            ]
        },
    };




    const contentData = [
        {
            title: "Rising costs for application maintenance",
            content: `Outsource your entire application management, maintenance, and security lifecycle without hassle

Reduce redundancies, embrace tailored solutions, automate processes to truncate costs at every stage of the application journey`,
            image: cloudImg1
        },
        {
            title: "Lack of universality",
            content: `Gain a universal dashboard overview for all applications including private IT environments, private clouds, multiple adopted clouds, third party enterprise systems, hybrid cloud environments (SAP, Oracle, Infosys, Databases)

Manage all diverse applications and integrated landscapes from a single pane of glass on hyperscaler cloud such as Azure and AWS (Use Azure Stack, Azure Arc, AWS Outposts) or private, hybrid cloud of choice`,
            image: cloudImg1
        },
        {
            title: "Stunted performance limited by backend",
            content: `Go beyond on-prem IT limitations and adopt virtual backend infrastructure to support/host applications

Scale application performance basis requirements, gain all-time availability and hyper agility`,
            image: cloudImg1
        },
        {
            title: "Operational disruptions, thwarting business as usual",
            content: "Globally renowned Japanese manufacturer embraces agile, advanced private cloud managed services to witness breakthrough operational efficiency sustaining 200,000 users concurrently at zero data loss and 99.95% uptime.",
            image: cloudImg1
        },
        {
            title: "Delays in Application issue management",
            content: `Specialized consultants ensure all application issues are met with ease, guaranteeing 24/7 monitoring and application support

Automate issue alerts and initiate remedy protocols`,
            image: cloudImg1
        },
        {
            title: "Lacks ad end to end application administration",
            content: `Bid adieu to all application management resource hassles with dedicated DevOps, infra engineering, maintenance, risk handling, application migration, delivery experts acting as an extended client team

Gain in-depth strategic, advisory, and consulting for application management end-to-end`,
            image: cloudImg1
        }
    ];



    const DIAACorpCloudComponent = () => {
        const [currentIndex, setCurrentIndex] = useState(2);
        const [visibleTitles, setVisibleTitles] = useState(5);

        useEffect(() => {
            const handleResize = () => {
                if (window.innerWidth < 640) {
                    setVisibleTitles(1);
                } else if (window.innerWidth < 768) {
                    setVisibleTitles(3);
                } else {
                    setVisibleTitles(5);
                }
            };

            handleResize();
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        const nextSlide = () => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % contentData.length);
        };

        const prevSlide = () => {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + contentData.length) % contentData.length);
        };

        const getVisibleTitles = () => {
            const start = Math.max(0, currentIndex - Math.floor(visibleTitles / 2));
            const end = Math.min(contentData.length, start + visibleTitles);
            return contentData.slice(start, end);
        };

        return (
            <div className="w-full max-w-7xl text-white mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <h1 className="text-xl sm:text-3xl lg:text-4xl font-bold text-center my-6 sm:my-12 lg:my-24">
                    Break the Legacy Barriers: Why Opt for Application Managed Services?
                </h1>

                <div className="relative mb-8">
                    <div className="flex justify-between items-center mb-4 sm:mb-8 lg:mb-12 overflow-x-auto no-scrollbar">
                        {getVisibleTitles().map((item, index) => (
                            <span
                                key={index}
                                className={`text-xs sm:text-sm lg:text-md whitespace-nowrap px-2 py-1 ${item.title === contentData[currentIndex].title
                                    ? 'bg-gradient-to-r from-[#a100ff] to-[#57CFFF] text-transparent bg-clip-text font-bold'
                                    : 'text-gray-500'
                                    }`}
                            >
                                {item.title}
                            </span>
                        ))}
                    </div>
                    <div className='mobile:mb-24'>

                        <button onClick={prevSlide} className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-theme pb-2 pt-1 px-2 rounded-full z-10 text-xl font-semibold">
                            ←
                        </button>
                        <button onClick={nextSlide} className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-theme p-2 pt-1 px-2 rounded-full z-10 text-xl font-semibold">
                            →
                        </button>

                    </div>

                    <AnimatePresence mode="wait">
                        <motion.div
                            key={currentIndex}
                            initial={{ opacity: 0, x: 100 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: -100 }}
                            transition={{ duration: 0.5 }}
                            className="flex flex-col sm:flex-row items-center justify-between sm:mx-4 lg:mx-12"
                        >
                            <div className="w-full sm:w-1/2 mb-4 sm:mb-0">
                                <div className="rounded-lg overflow-hidden border-2 border-[#a100ff]">
                                    <img
                                        src={contentData[currentIndex].image}
                                        alt={contentData[currentIndex].title}
                                        className="w-full h-auto"
                                    />
                                </div>
                            </div>
                            <div className="w-full sm:w-1/2 sm:pl-4 lg:pl-8">
                                <h2 className="text-xl sm:text-2xl font-bold mb-4 bg-gradient-to-r from-[#a100ff] to-[#57CFFF] text-transparent bg-clip-text">
                                    {contentData[currentIndex].title}
                                </h2>
                                <p className="text-gray-300 text-sm sm:text-base">
                                    {contentData[currentIndex].content}
                                </p>
                            </div>
                        </motion.div>
                    </AnimatePresence>
                </div>
            </div>
        );
    };



    const ApplicationMap = () => {
        const applications = [
            { name: 'Enterprise App', items: 4 },
            { name: 'Core Banking', items: 4 },
            { name: 'E-Commerce', items: 4 },
            { name: 'Information Mgmt.', items: 4 },
        ];

        const databases = [
            { name: 'SQL OpenSource Database', items: 4 },
            { name: 'SQL Commercial Database', items: 4 },
            { name: 'NoSQL Database Database', items: 4 },
        ];

        const renderItems = (count) => (
            Array(count).fill().map((_, index) => {
                const imageObject = images[index % images.length];
                return (
                    <div key={index} className="w-8 h-8 sm:w-12 sm:h-12 md:w-16 md:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24 rounded-full bg-gray-700 overflow-hidden">
                        <img src={imageObject.src} alt={imageObject.alt} className="w-full h-full object-cover" />
                    </div>
                );
            })
        );

        const renderSection = (title, items) => (
            <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4 mb-6 justify-center">
                <div className="w-full sm:w-40 md:w-48 lg:w-56 py-2 px-4 rounded-full text-xs sm:text-sm md:text-base text-center" style={{
                    border: "2px solid",
                    borderImage: "linear-gradient(90deg, #a100ff, #57CFFF) 1",
                    borderRadius: "9999px",
                }}>
                    {title}
                </div>
                <div className="flex flex-wrap justify-center gap-2 sm:gap-4">
                    {renderItems(items)}
                </div>
            </div>
        );

        return (
            <div className="p-4 sm:p-6 md:p-8 mt-6 md:mt-12 w-full max-w-7xl mx-auto">
                <div className="space-y-8 sm:space-y-12 md:space-y-16">
                    <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-white mb-6 sm:mb-8 text-center">
                        Sneak Map: <span className='text-theme'>Key Enterprise Applications Managed by DIAA Corp</span>
                    </h2>
                    <div className="space-y-6 sm:space-y-8 md:space-y-12">
                        {applications.map((app, index) => (
                            <React.Fragment key={index}>
                                {renderSection(app.name, app.items)}
                            </React.Fragment>
                        ))}
                    </div>
                    <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-white mb-6 sm:mb-8 text-center">
                        Overview: <span className='text-theme'>Key Application Databases Managed by DIAA Corp</span>
                    </h2>
                    <div className="space-y-6 sm:space-y-8 md:space-y-12">
                        {databases.map((db, index) => (
                            <React.Fragment key={index}>
                                {renderSection(db.name, db.items)}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const AutomationEnabled = () => {
        return (
            <div className="bg-[#14192E] text-white flex justify-center items-center mobile:px-2 py-6 md:py-16">
                <div className="max-w-6xl mx-auto">
                    <div className="flex flex-col md:flex-row justify-between items-center mb-8">
                        <div className="md:w-1/2 pr-4">
                            <h1 className="text-xl md:text-3xl font-bold mb-4 mobile:text-center">DIAA Corp Automation-enabled, End-to-end Enterprise Application Managed Services: Leverage DIAA Corp Proprietary SHOP Platform</h1>
                            {/* <p>Modern businesses need to be flexible in synonymy to an ever-fluctuating market. As a result, firms are often equipped with multiple architectural challenges followed by frequent IT upgrade and renovation demands. Such agility could be best achieved by adopting digital-first operational models using SAP Business Suite and leveraging the latter’s true potential by hosting all SAP workloads, applications, databases on the cloud.</p> */}
                        </div>
                        <div className="md:w-1/2 pl-4 mt-8 md:mt-0">
                            <p className='mobile:text-sm'>DIAA Corp, the world’s largest application-focused cloud managed services provider, positions itself as the one-stop shop for all application managed service and application transformation needs. Design a high-performance cloud architecture for your applications and leverage the Zero Friction Migration Factory Approach to transition your mission-critical applications and databases on any public, private, hybrid, or multi-cloud architecture of choice.</p>
                        </div>
                    </div>
                    <div className="text-left mb-8 mobile:text-sm">
                        <p>Supported by robust industry-grade processes and DIAA Corp’s Self Healing Operations Platform (SHOP), modernize core assets and leverage fully automated AIOps-powered managed services to run the applications on the deployed cloud at maximum availability, performance agility, workload scalability, and world-class security. Monitor application infrastructure health 24/7 and ensure military-grade protection from advanced threats. Ensure seamlessly compliant application operations while asserting the highest levels of continuity. Avail world-class enterprise application experience at a single SLA.</p>
                    </div>
                </div>
            </div>
        );
    };

    const ServiceImpactManagement = () => {
        const hexagonItems = [
            { text: 'Service level management', position: 'col-start-2 row-start-1 mobile:col-start-1' },
            { text: 'Service desk', position: 'col-start-3 row-start-1 mobile:col-start-2' },
            { text: 'Capacity management', position: 'col-start-1 row-start-2 mobile:col-start-1 mobile:row-start-2' },
            { text: 'Asset management', position: 'col-start-4 row-start-2 mobile:col-start-2 mobile:row-start-2' },
            { text: 'Change management', position: 'col-start-2 row-start-3 mobile:col-start-1 mobile:row-start-3' },
            { text: 'Event management', position: 'col-start-3 row-start-3 mobile:col-start-2 mobile:row-start-3' },
            { text: 'Event management', position: 'col-start-2 col-span-2 row-start-4 mobile:col-start-1 mobile:col-span-2 mobile:row-start-4' },
        ];

        const infrastructureItems = [
            'Storage', 'Database', 'Middleware', 'Network', 'Server', 'Desktop', 'Cloud infra'
        ];

        return (
            <div className="p-4 flex flex-col items-center justify-center my-4 md:my-12">
                <div className="max-w-4xl w-full">
                    <div className="grid grid-cols-4 gap-4 mb-8 mobile:grid-cols-2 mobile:gap-2">
                        {hexagonItems.map((item, index) => (
                            <div key={index} className={`${item.position} flex items-center justify-center`}>
                                <div className="relative w-40 h-48 mobile:w-32 mobile:h-36">
                                    <svg viewBox="0 0 150 150" className="w-full h-full">
                                        <polygon
                                            points="75 1.5 142.5 37.5 142.5 112.5 75 148.5 7.5 112.5 7.5 37.5"
                                            fill="transparent"
                                            stroke="url(#blue-gradient)"
                                            strokeWidth="2"
                                        />
                                        <text x="75" y="75" dominantBaseline="middle" textAnchor="middle" fill="white" fontSize="9" className="">
                                            {item.text}
                                        </text>
                                        <defs>
                                            <linearGradient id="blue-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                                <stop offset="0%" stopColor="#a100ff" />
                                                <stop offset="100%" stopColor="#57CFFF" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        ))}
                        <div className="col-start-2 col-span-2 row-start-2 flex items-center justify-center mobile:col-start-1 mobile:col-span-2 mobile:row-start-5">
                            <p className="text-xl font-bold mobile:text-lg">Service Impact management</p>
                        </div>
                    </div>

                    <div className="mt-8 ">
                        <div className="border-t-2 border-blue-400 w-1/6 mx-auto mb-4"></div>
                        <div className="bg-[#1D233A] rounded-lg p-4 md:mt-24 md:py-12">
                            <p className="text-center mb-4 mobile:text-sm ">Batch & Online applications performance and availability</p>
                            <div className="flex flex-wrap justify-center gap-4 mobile:gap-2 mt-8">
                                {infrastructureItems.map((item, index) => (
                                    <div key={index} className="border border-theme rounded-full px-4 py-2 text-sm mobile:text-xs mobile:px-2 mobile:py-1">
                                        {item}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const tab1 = [
        'Application deployment and provisioning',
        'Full scale application and managed services',
        'Application database management',
        'DIAA CORP Migration factory for applications',
        // Add more items as needed
    ];

    const tab2 = [
        'DevOps and DevSecOps',
        'Cast optimization',
        'Application security',
        'Application monitoring and event management'
    ];

    const tab3 = [
        'Application change management',
        'Patch and business continuity management',
        'Disaster recovery and backup',
        'Support and consulting services'
    ];

    const content1 = [
        <p>Leverage DIAA Corp Migration Factory to ensure step-wise, fool-proof, and frictionless application migration and transformation on the cloud. Develop a custom transformation blueprint and leverage lean, metric- driven automated processes supported by effective management, automation, and intuitive dashboards.</p>,
        <p>Enable seamless deployment of cloud infrastructure of choice (private, hybrid, public, multi-cloud) with on-demand provisioning of critical cloud stacks and services per application ops requirements. Integrate with enterprise’s existing ITSM service catalog to quickly set-up applications in either test or production environment.</p>,
        <p>Ensure hassle-free management and maintenance of business applications deployed on the cloud architecture of choice. Leverage DIAA Corp SHOP to unlock fully automated, security-integrated, and AIOps-powered application managed services. DIAA Corp ensures maximum availability from infrastructure till app login layer.</p>,
        <p>Migrate, modernize, and manage application dataflows and databases with ease on the cloud platform. Ensure seamless data collection, aggregation, integrity, governance, security, and compliance. Make sure application data operations run at ultra-low latencies without any compromises</p>,
        // Add more items as needed
    ];

    const content2 = [
        <p>Transform application operations lifecycle with DevOps and DevSecOps. Embed Microservices architectures, containerization, Kubernetes, orchestration, Infrastructure as a Code. Leverage DIAA Corp's key toolkit expertise in CI/CD pipeline, coding, systems building, automation, and testing.</p>,
        <p>Run applications on cloud to gain maximum returns at minimum expenses. Gain monthly summaries of key performance metrics including operational activities, events, and their respective impacts. Leverage DIAA Corp’s dedicated Cloud Cost Optimization practices to optimize usage while not compromising performance.</p>,
        <p>Monitor applications 24/7 to look for security loopholes, planned and unplanned intrusions, malware attacks, or vulnerabilities. Embed application environments with advanced threat management practices including MDR, Managed SOC, Threat Intelligence, Security Analytics, Incident and Access Management, and more.</p>,
        <p>Monitor health of application infra and platforms 24/7. Access and analyze logs to look for vulnerabilities or data losses, breaches. Detect threat events and resolve incidents with automated, proactive remediation protocols. Gain full visibility of application health and risk posture from intuitive dashboards.</p>
    ];

    const content3 = [
        <p>DIAA Corp provides secure and efficient means to make controlled changes to your application infrastructure in order to ensure periodic performance improvements, security and compliance enhancements, and more. Changes are approved and automated through our approval engine, and can be scheduled via self-service.</p>,
        <p>Automated patching and backup activities in a timely and consistent manner to help keep your application resources, databases, workloads remain duly updated, vulnerability-proof, and hence secure. Critical security patches are applied immediately, while others are applied based on the patch schedule you request.</p>,
        <p>Ensure seamless continuity of your cloud hosted applications regardless of events and maintain highest availability of upto 99.95%. DIAA Corp provides a simple, cost-effective, modern, and automated data backup and recovery strategies with DRaaS frameworks custom-designed to meet the demands of enterprises and delivering uninterrupted services.</p>,
        <p>Gain in-depth access to enterprise application consulting and support services anytime anywhere, a concierge-like service providing 24x7 technical support from high-quality engineers, tools, and technology to automatically manage the health of your environment. We augment and empower your team with a fully staffed service desk to answer questions, resolve incidents and perform and manage complex change requests.</p>
    ];

    const CloudManagedTabs = ({ }) => {
        return (
            <div className="w-full md:w-11/12 mx-auto p-4 md:p-6 bg-darkBlue-900 text-white rounded-lg mt-8 md:mt-24">
                <h2 className="text-xl md:text-3xl font-bold text-center mb-4 md:mb-8">
                    DIAA Corp End-to-end Managed Services for Mission critical Enterprise Applications
                </h2>
                {/* <p className='mt-6 md:mt-12 text-center'>
                    Focus on the Core Offering. Leave all your IT operations woes to us. Succeed, scale, and innovate on the cloud.
                </p> */}
            </div>
        );
    }


    const KnowledgeDesk = () => {
        const [activeStory, setActiveStory] = useState('Falconpack');

        const cards = [
            {
                title: 'Application Hosting',
                description: 'While organizations utilize a plethora of complex enterprise applications and systems to address their mission-critical operations, hosting and securing them on private datacenters is no easy feat. DIAA Corp securely hosts all your enterprise.',
            },
            {
                title: 'Application Modernization',
                description: 'Leverage the 6R approach to application modernization. DIAA Corp’s exhaustive development, engineering, full-stack architecture, maintenance, security experts act as an extended team to modernize a client’s application environment end-to-end.',
            },
            {
                title: 'Compliance-as-a-Service',
                description: 'With rising data concerns across the world, companies must handle the data within the purview of the law. DIAA Corp has the ability to create a complex scenario where the data hosting adhere to the localization laws but the application is placed on public cloud for maximum performance.',
            },
            // Add other cards here
        ];
        return (
            <div className="text-white p-8 mt-12 md:mt-24 md:w-11/12 mx-auto">
                <h2 className="text-xl md:text-3xl font-bold mb-6 md:text-center ">Beyond the Traditional Boundaries: DIAA Corp Additional Services for Enterprise Application Transformation on Cloud</h2>

                <div className="grid grid-cols-1 md:grid-cols-3 my-12 mobile:mb-12 md:mb-24 md:mt-24">
                    {cards.map((card, index) => (
                        <div key={index} className="bg-[#14192E] p-6 rounded-2xl md:w-11/12 mx-auto mobile:mb-4">
                            <h3 className="text-xl font-semibold mb-4">{card.title}</h3>
                            <p className="mb-4">{card.description}</p>
                            <button className="text-theme hover:text-blue-300 transition-colors duration-300">
                                Read more <span className="ml-2">&raquo;</span>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        );
    };
    const OurSuccessStories = () => {
        const [activeStory, setActiveStory] = useState('BESCOM');

        const stories = {
            BESCOM: {
                title: 'BESCOM',
                description: 'Leading electricity and utility supplier organization obtains uninterrupted application availability and seamless performance through Multilayer security and pre-integrated DR.',
                image: ourSuccessImg3,
            },
            'Oil and Gas major': {
                title: 'Oil and Gas major',
                description: 'Global Food Packaging Manufacturer leverages customized SAP HANA Architecture on AWS to achieve breakthrough operational efficiency with 99.95% Uptime.',
                image: ourSuccessImg3,
            },
            'Global Ceramics Brand': {
                title: 'Global Ceramics Brand',
                description: 'Global Food Packaging Manufacturer leverages customized SAP HANA Architecture on AWS to achieve breakthrough operational efficiency with 99.95% Uptime.',
                image: ourSuccessImg3,
            },
            'Jaleel Holdings': {
                title: 'Jaleel Holdings',
                description: 'Global Food Packaging Manufacturer leverages customized SAP HANA Architecture on AWS to achieve breakthrough operational efficiency with 99.95% Uptime.',
                image: ourSuccessImg3,
            },
            // Add other stories here
        };


        return (
            <div className='md:w-11/12 mx-auto px-4 lg:px-0'>

                <h2 className="text-3xl font-bold mb-6 text-center">Our Success Stories</h2>

                <div className="flex flex-wrap gap-4 mb-8 justify-center mt-12 md:my-12 md:space-x-8">
                    {Object.keys(stories).map((storyKey) => (
                        <button
                            key={storyKey}
                            className={`px-4 md:px-6 py-2 rounded-full text-[#F1F1F1] ${activeStory === storyKey
                                ? 'bg-custom-gradient'
                                : 'border border-[#F1F1F1] hover:bg-gray-600'
                                } transition-colors duration-300`}
                            onClick={() => setActiveStory(storyKey)}
                        >
                            {storyKey}
                        </button>
                    ))}
                </div>

                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeStory}
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -20 }}
                        transition={{ duration: 0.3 }}
                        className="md:flex items-center"
                    >
                        <img
                            src={stories[activeStory].image}
                            alt={stories[activeStory].title}
                            className="md:w-1/2 rounded-lg mr-8 mobile:mb-2"
                        />
                        <div>
                            <h3 className="text-2xl font-semibold mb-4">{stories[activeStory].title}</h3>
                            <p>{stories[activeStory].description}</p>
                        </div>
                    </motion.div>
                </AnimatePresence>
            </div>
        )
    }

    const features = [
        { icon: <FaGlobe />, text: "One of the most trusted Managed Cloud Service and Application Managed Services Providers in APAC, MEA, and the Americas for 12+ years" },
        { icon: <FaCloud />, text: "World's largest Application-focused, high-end managed services provider with AlOps-driven Managed Operations" },
        { icon: <FaClock />, text: "24/7 Support backed by 2000+ cloud certified experts adept with robust ITIL, ITSM, COBIT delivery processes, 25 dedicated Centers of Excellence" },
        { icon: <FaLock />, text: "Zero Friction Application Migration and Application Managed Service Model with industry-leading Cloud Adoption Factory approach, 25000+ Apps, and Databases migrated" },
        { icon: <FaGlobe />, text: "4000+ enterprise customers including 60 of Fortune 500 companies including 5 of Top 20 Global Banks" },
        { icon: <FaCloud />, text: "Presence in 26 nations worldwide including successful deployment of Public, Private, Hybrid, Multi, and Community Cloud platforms" },
        { icon: <FaChartBar />, text: "High availability 99.95%, hyper-scalability, industry-best uptime in Application Managed Services" },
        { icon: <FaDatabase />, text: "Proven SAP on Cloud expertise managing 10,000+ SAP instances and 3000+ TB of HANA Database" },
        { icon: <FaCloud />, text: "Proven expertise with IBM, Oracle, Opentext, Infosys, and cloud-native enterprise applications management including SaaS, PaaS, laaS transformation" },
        { icon: <FaGlobe />, text: "Host and deploy applications at the region of choice ensuring high availability and zero disruptions" },
        { icon: <FaLock />, text: "Proven end-to-end Application Modernization expertise with dedicated development, engineering, maintenance, administration, security monitoring, and support expertise" },
        { icon: <FaCloud />, text: "Dedicated DR on Cloud offerings for heterogeneous, complex environments with automated recovery-backup, failback-failover mechanisms" },
        { icon: <FaLock />, text: "Dedicated Application Managed Security Services Expertise, 40+ Security Controls, dedicated SOCS" },
        { icon: <FaChartBar />, text: "Strict compliance to regulatory and in-country data residency standards" },
    ];


    const CloudDifferenceBoxes = () => {
        return (
            <FeatureGrid
                title="Why sign-up for Managed Cloud Services from DIAA Corp?"
                features={features}
            />
        );
    };

    const FAQSection = () => {
        const faqs = [
            {
                question: "What is Application Management System?",
                answer: `An application management system is a unified platform to monitor, engage, run, and access all enterprise applications entitling an organization’s core functionalities. DIAA Corp Application managed services manage the availability, scalability, and security of applications too in addition to allowing users access all components, networks, and backend architectures as and when required..
                `
            },
            {
                question: "What is included in Application managed services?",
                answer: "Moving to SAP on Cloud offers benefits like scalability, cost-efficiency, and easier maintenance compared to on-premises solutions. Cloud solutions provide greater flexibility and can be accessed from anywhere, enhancing collaboration and productivity."
            },
            {
                question: "What is the responsibility of application management?",
                answer: "DIAA Corp's SAP Switch2Cloud is a customized migration solution, while SAP RISE Switch2Cloud is SAP's bundled offering. DIAA Corp's solution may offer more flexibility and tailored services, whereas SAP RISE provides an all-in-one package directly from SAP."
            },
        ];

        return (
            <div className="flex items-center justify-center p-4">
                <div className="max-w-3xl w-full">
                    <h1 className="text-3xl font-bold text-white text-center mb-8 md:mb-16">
                        Enterprise Application Managed Services -FAQs
                    </h1>
                    {faqs.map((faq, index) => (
                        <FAQItem key={index} question={faq.question} answer={faq.answer} />
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="min-h-screen text-white">
            <Header {...headerData} isExploreBtn={true} backgroundImage={EnterpriseCover} />

            <div>
                <MigrationInfo {...migrationInfoData} isMigrationFactory={false} />
            </div>

            <div className='bg-[#14192E]'>
                <DIAACorpCloudComponent />
            </div>

            <div className=''>
                <ApplicationMap />
            </div>

            <div className='mt-12 md:mt-24'>
                <ContactContent title={`Eager to transform your applications and assets on the cloud?`} />
            </div>

            <div className='mt-12 md:mt-24 md:py-24 md:w-11/12 mx-auto mobile:px-2'>
                <h2 className="text-xl md:text-3xl font-bold text-center mb-4 md:mb-8">
                    Words that makes us go the extra mile
                </h2>
                <p className='mt-2 md:mt-6 text-center mb-12 text-gray-300'>
                    “Jaleel Holdings is glad to work with a cloud service provider like DIAA Corp who took each and every step to ensure that all our business challenges are resolved with ease. The certified cloud specialists analyzed the business environment and suggested the right architecture that is cost-effective. It is no exaggeration to mention that the credit for our hassle-free business operations goes to DIAA Corp. Their customer-centric approach and commitment to excellence are commendable”
                </p>
            </div>

            <div>
                <AutomationEnabled />
            </div>

            <div>
                <ServiceImpactManagement />
            </div>

            <div>
                <CloudManagedTabs />

                <Slider tabs={tab1} content={content1} />
                <Slider tabs={tab2} content={content2} />
                <Slider tabs={tab3} content={content3} />
            </div>

            <div>
                <KnowledgeDesk />
            </div>

            <div>
                <ContactContent title={`Connect with our Application Managed Service Experts`} />
            </div>

            <div className='mx-auto 4xl:py-16'>
                <LocationMap />
            </div>

            <div className=''>
                <OurSuccessStories />
            </div>

            <div className='md:w-11/12 mx-auto'>
                <CloudDifferenceBoxes />
            </div>

            <div className='mt-12 md:mt-24'>
                <FAQSection />
            </div>

            <Footer />

        </div>
    )
}

export default EnterpriseApplications