import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import BrowserRouter
import Home from './Home';
import Navbar from '../components/navbar/Navbar';
import RiseWithSap from './RiseWithSap';
import ComingSoon from './ComingSoon';
import SapS4hana from './SapS4hana';
import SapAriba from './SapAriba';
import SapBusinessBydesign from './SapBusinessBydesign';
import SapCx from './SapCx';
import SapSuccessFactors from './SapSuccessFactors';
import SAPMigrationFactory from './SAPMigrationFactory';
import CloudManagedServices from './CloudManagedServices';
import EnterpriseApplications from './EnterpriseApplications';
import SapConsulting from './SapConsulting';
import SapManagedServices from './SapManagedServices';
import ApplicationDevelopmentPage from './ApplicationDev';

function AppRouter() {
    return (
        <Router> {/* Wrap Routes in Router */}
            <div style={{ paddingTop: '64px' }}>
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/rise-with-sap" element={<RiseWithSap />} />
                    <Route path="/sap-s4hana" element={<SapS4hana />} />
                    <Route path="/sap-ariba" element={<SapAriba />} />
                    <Route path="/sap-business-by-design" element={<SapBusinessBydesign />} />
                    <Route path="/sap-cx" element={<SapCx />} />
                    <Route path="/sap-successfactors" element={<SapSuccessFactors />} />
                    <Route path="/sap-migration-factory" element={<SAPMigrationFactory />} />
                    <Route path="/cloud-managed-services" element={<CloudManagedServices />} />
                    <Route path="/enterprise-applications-managed-services" element={<EnterpriseApplications />} />
                    <Route path="/sap-consulting" element={<SapConsulting />} />
                    <Route path="/sap-managed-services" element={<SapManagedServices />} />
                    <Route path="/application-development" element={<ApplicationDevelopmentPage />} />
                    
                    
                    <Route path="*" element={<ComingSoon />} />
                    {/* Add more routes here */}
                </Routes>
            </div>
        </Router>
    );
}

export default AppRouter;
