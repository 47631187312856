import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import DIAA_NAME from '../assets/DIAA_Trademark.png';

const Footer = () => {
  const location = useLocation();
  const isRiseWithSapRoute = location.pathname === '/rise-with-sap';
  const isSap4hana = location.pathname === '/sap-s4hana';
  const Home = location.pathname === '/';

  const [isFormFocused, setIsFormFocused] = useState(false);

  const handleFormFocus = () => {
    setIsFormFocused(true);
  };

  const handleFormBlur = () => {
    setIsFormFocused(false);
  };

  return (
    <footer className="text-white py-8 pt-12 2xl:pt-32">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-lg font-semibold mb-4">Preference center</h3>
            <ul>
              <li className="mb-2"><a href="#" className="hover:text-gray-300">Careers</a></li>
              <li className="mb-2"><a href="#" className="hover:text-gray-300">About Us</a></li>
              <li className="mb-2"><a href="#" className="hover:text-gray-300">Contact Us</a></li>
              <li className="mb-2"><a href="#" className="hover:text-gray-300">Location</a></li>
            </ul>
          </div>
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-lg font-semibold mb-4">Privacy Statement</h3>
            <ul>
              <li className="mb-2"><a href="#" className="hover:text-gray-300">Terms and condition</a></li>
              <li className="mb-2"><a href="#" className="hover:text-gray-300">Cookie Policy/Settings</a></li>
              <li className="mb-2"><a href="#" className="hover:text-gray-300">Accessibility/Settings</a></li>
            </ul>
          </div>
          {!Home ? (
            <div className="w-full md:w-1/3">
              <motion.h3
                className="text-lg font-semibold mb-4"
                animate={isFormFocused ? {} : { scale: [1, 1.05, 1] }}
                transition={{ duration: 2, repeat: Infinity }}
              >
                {isSap4hana ? 'Service form' : 'Enquire Now'}
              </motion.h3>
              <form onFocus={handleFormFocus} onBlur={handleFormBlur}>
                <input type="text" placeholder="Enter your name" className="w-full mb-4 p-2 bg-[#1A1F31] rounded" />
                <input type="email" placeholder="Enter your email" className="w-full mb-4 p-2 bg-[#1A1F31] rounded" />
                <input type="tel" placeholder="Enter your mobile number" className="w-full mb-2 p-2 bg-[#1A1F31] rounded" />
                <motion.button
                  type="submit"
                  className="bg-custom-gradient hover:bg-blue-700 text-white font-bold py-2 px-12 rounded-full mt-4 hover:bg-blue-600 transition duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Send
                </motion.button>
              </form>
            </div>
          ) : (
            <div className="w-full md:w-1/3 flex items-center justify-center md:justify-end">
              <img src={DIAA_NAME} alt="Laptop" className="w-4/5" />
            </div>
          )}
        </div>
        <div className="mt-8 text-center text-sm">
        <p className='text-gray-400'>&copy; {new Date().getFullYear()} DIAA Corp. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;