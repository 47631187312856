import React from 'react';

function MigrationFactory() {
  const features = [
    { name: 'Automated Approach', icon: '🚀', left: false },
    { name: 'Industry best Practices', icon: '🌱', left: true },
    { name: 'Certified process', icon: '📄', left: false },
    { name: 'Leading tools', icon: '⚙️', left: true },
    { name: 'Trained team', icon: '👥', left: false },
  ];

  return (
    <div className="bg-[#070727] text-white p-4 sm:p-8 font-sans">
      <h2 className="text-xl sm:text-2xl font-bold mb-8 sm:mb-12 text-center">Migration Factory</h2>

      <div className="relative max-w-3xl mx-auto">
        {/* Vertical line */}
        <div className="absolute left-1/2 top-0 bottom-0 w-0.5 bg-[#40435F] transform -translate-x-1/2"></div>

        {/* Features */}
        <div className="space-y-8 sm:space-y-16">
          {features.map((feature, index) => (
            <div key={index} className="flex items-center justify-center">
              <div className={`w-full ${feature.left ? 'pr-4 sm:pr-8 text-right' : 'pl-4 sm:pl-8 text-left'}`}>
                <div className="bg-[#161830] py-3 sm:p-4 rounded-lg inline-flex items-center space-x-2 relative">
                  {/* Line from dot to feature */}
                  <div className={`absolute top-1/2 ${feature.left ? 'right-full' : 'left-full'} w-2 sm:w-12 h-0.5 bg-[#40435F]`}></div>
                  <span className="hidden md:block text-[#a100ff] text-xs sm:text-xl">{feature.icon}</span>
                  <span className="text-xs sm:text-sm">{feature.name}</span>
                </div>
              </div>
              <div className="w-4 h-4 sm:w-5 sm:h-5 bg-custom-gradient rounded-full absolute left-1/2 transform -translate-x-1/2 shadow-[0_0_20px_#a100ff] z-10"></div>
            </div>
          ))}
        </div>
      </div>

      {/* Advantages */}
      <div className="mt-12 sm:mt-20">
        <h3 className="text-lg sm:text-xl text-center font-bold mb-8 sm:mb-12">Advantages</h3>
        <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
          {['Faster Migration to cloud', 'Highly secured', 'Lowest TCO'].map((advantage, index) => (
            <div key={index} className="bg-[#161830] p-4 rounded-2xl flex-1 text-center relative overflow-hidden sm:ml-12">
              <div className="absolute top-0 left-0 w-1 h-full bg-gradient-to-b from-[#a100ff] to-[#57CFFF]"></div>
              <p className="text-xs sm:text-sm py-4 sm:py-6">{advantage}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MigrationFactory;