import React, { useState, useEffect, useRef } from 'react';
import { motion, useScroll, useTransform, useInView } from 'framer-motion';
import { ArrowRight, Code, Database, Layout, Server, Smartphone, CheckCircle, Users, Zap, Cloud, Mail, Phone, MapPin, ArrowDown } from 'lucide-react';
import { useDocumentTitle } from '../utils/useDocumentTitle'
import App_dev_cover from '../assets/AppDevelopment/App_dev_cover.png'
import { Header } from '../components/resuable/SapSolution'

import HTML_Logo from '../assets/AppDevelopment/HTML_Logo.png';
import CSS_Logo from '../assets/AppDevelopment/CSS_Logo.png';
import EXPRESSJS_Logo from '../assets/AppDevelopment/EXPRESSJS_Logo.png';
import Google_Cloud_Logo from '../assets/AppDevelopment/Google_Cloud_Logo.png';
import Digital_Ocean_Logo from '../assets/AppDevelopment/Digital_Ocean_Logo.png';
import JS_Logo from '../assets/AppDevelopment/JS_Logo.png';
import MongoDB_Logo from '../assets/AppDevelopment/MongoDB_Logo.png';
import Mysql_Logo from '../assets/AppDevelopment/Mysql_Logo.png';
import NEXTJS_Logo from '../assets/AppDevelopment/NEXTJS_Logo.png';
import NodeJS_Logo from '../assets/AppDevelopment/NodeJS_Logo.png';
import Python_Logo from '../assets/AppDevelopment/Python_Logo.png';
import React_Native from '../assets/AppDevelopment/React_Native.png';
import Tailwindcss_Logo from '../assets/AppDevelopment/Tailwindcss_Logo.png';
import Typescript_Logo from '../assets/AppDevelopment/Typescript_Logo.png';
import Amazon_web_services_Logo from '../assets/AppDevelopment/Amazon_web_services_Logo.png';
import Docker from '../assets/AppDevelopment/Docker.svg';
import Graphql from '../assets/AppDevelopment/Graphql.svg';
import Flutter from '../assets/AppDevelopment/Flutter.svg';
import Kubernetes from '../assets/AppDevelopment/Kubernetes.svg';
import Tensorflow from '../assets/AppDevelopment/Tensorflow.svg';
import Vue from '../assets/AppDevelopment/Vue.svg';
import ReactSvg from '../assets/AppDevelopment/React.svg';
import Footer from '../components/Footer';
import CoverVideo from '../assets/AppDevelopment/Globe_Cover.mp4'

const techImages = {
    HTML: HTML_Logo,
    CSS: CSS_Logo,
    Express: EXPRESSJS_Logo,
    Google_Cloud: Google_Cloud_Logo,
    JavaScript: JS_Logo,
    MongoDB: MongoDB_Logo,
    MySQL: Mysql_Logo,
    Next_js: NEXTJS_Logo,
    Node_js: NodeJS_Logo,
    Python: Python_Logo,
    React_Native: React_Native,
    React: ReactSvg,
    Tailwind: Tailwindcss_Logo,
    TypeScript: Typescript_Logo,
    AWS: Amazon_web_services_Logo,
    Digital_Ocean_Logo,
    Docker,
    Graphql,
    Flutter,
    Kubernetes,
    Tensorflow,
    Vue
};


const getTechImage = (tech) => {
    const normalizedTech = tech.replace(/\s+/g, '').toLowerCase();
    const matchingTech = Object.keys(techImages).find(
        key => key.toLowerCase() === normalizedTech
    );

    return matchingTech ? techImages[matchingTech] : null;
};

const expertiseData = [
    { icon: Layout, title: "Frontend Development", description: "Crafting visually stunning, responsive interfaces with the latest design frameworks.", tech: ["HTML", "CSS", "React", "Next_js"] },
    { icon: Server, title: "Backend Development", description: "Developing scalable, high-performance server-side applications with a focus on speed and efficiency.", tech: ["Node_js", "Python", "Express"] },
    { icon: Database, title: "Database Management", description: "Implementing reliable, scalable, and high-performance database solutions tailored to your application's specific needs.", tech: ["MySQL", "MongoDB"] },
    { icon: Smartphone, title: "Mobile Development", description: "Delivering cross-platform mobile applications that run smoothly on both iOS and Android devices.", tech: ["React_Native"] },
    { icon: Code, title: "DevOps & CI/CD", description: "Streamlining development processes and ensuring smooth deployments with cutting-edge DevOps practices.", tech: ["TypeScript", "JavaScript"] },
    { icon: Cloud, title: "Cloud Solutions", description: "Leveraging cloud technologies to ensure your applications are always available, secure, and scalable.", tech: ["AWS", "Google_Cloud", "Digital_Ocean_Logo"] },
];

const processSteps = [
    { step: 1, title: "Discovery", description: "We start by thoroughly understanding your business goals, target audience, and project requirements." },
    { step: 2, title: "Planning", description: "Our team creates a comprehensive project plan, including timelines, milestones, and resource allocation." },
    { step: 3, title: "Design", description: "We design intuitive user interfaces and engaging user experiences tailored to your brand and users." },
    { step: 4, title: "Development", description: "Our developers bring the designs to life, writing clean, efficient, and scalable code." },
    { step: 5, title: "Testing", description: "Rigorous testing ensures your application is bug-free, secure, and performs optimally." },
    { step: 6, title: "Deployment", description: "We carefully launch your application, ensuring a smooth transition to the live environment." },
    { step: 7, title: "Maintenance", description: "Our team provides ongoing support, updates, and enhancements to keep your application running smoothly." },
];

const testimonialsData = [
    { name: "Johnathan", company: "Tech Innovators Inc.", quote: "The team's expertise and dedication resulted in an application that exceeded our expectations." },
    { name: "Rashid", company: "Global Solutions Ltd.", quote: "Their innovative approach to problem-solving sets them apart from other development agencies." },
    { name: "Dinoop", company: "StartUp Ventures", quote: "The application they built for us has been instrumental in our rapid growth and success." },
];

const AnimatedCounter = ({ target, duration = 2000 }) => {
    const [count, setCount] = useState(0);
    const counterRef = useRef(null);
    const isInView = useInView(counterRef);

    useEffect(() => {
        if (isInView) {
            let startTime;
            const animate = (time) => {
                if (!startTime) startTime = time;
                const progress = (time - startTime) / duration;
                if (progress < 1) {
                    setCount(Math.floor(target * progress));
                    requestAnimationFrame(animate);
                } else {
                    setCount(target);
                }
            };
            requestAnimationFrame(animate);
        }
    }, [isInView, target, duration]);

    return <span ref={counterRef}>{count}</span>;
};

const ParallaxSection = ({ children, offset = 50 }) => {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["start end", "end start"]
    });

    const y = useTransform(scrollYProgress, [0, 1], [offset, -offset]);

    return (
        <motion.div ref={ref} style={{ y }}>
            {children}
        </motion.div>
    );
};

const ExpertiseCard = ({ icon: Icon, title, description, tech }) => (
    <motion.div
        className="flex flex-col items-center p-6 bg-opacity-5 bg-white rounded-lg  border-4 border-transparent hover:border-gradient-to-r hover:from-[#4F75FF] hover:to-[#a100ff]"
        whileHover={{ scale: 1.05, boxShadow: "0px 0px 20px rgba(161, 0, 255, 0.3)" }}
        transition={{ type: "spring", stiffness: 300 }}
    >
        <Icon size={48} className="text-theme mb-4" />
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-center text-gray-300 mb-4">{description}</p>
        <div className="w-full p-4 rounded-lg ">
            <div className="flex flex-wrap justify-center gap-4">
                {tech.map((t, index) => {
                    const imgSrc = getTechImage(t);
                    return imgSrc ? (
                        <div key={index} className="relative group">
                            <img
                                src={imgSrc}
                                alt={t}
                                className="w-36 h-24 object-contain transition-all duration-300 group-hover:scale-110"
                                style={{ filter: 'drop-shadow(0px 0px 1px rgba(255, 255, 255, 1))' }}
                            />
                        </div>
                    ) : null;
                })}
            </div>
        </div>
    </motion.div>
);

const ProcessStep = ({ step, title, description }) => (
    <motion.div
        className="flex items-start space-x-4 p-6 bg-opacity-5 bg-white rounded-lg shadow-lg"
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
    >
        <div className="w-12 h-12 bg-custom-gradient rounded-full flex items-center justify-center flex-shrink-0">
            <span className="text-white font-bold">{step}</span>
        </div>
        <div>
            <h3 className="text-xl font-semibold mb-2">{title}</h3>
            <p className="text-gray-300">{description}</p>
        </div>
    </motion.div>
);

const TestimonialCard = ({ name, company, quote }) => (
    <motion.div
        className="bg-opacity-5 bg-white p-6 rounded-lg shadow-lg"
        initial={{ opacity: 0, scale: 0.9 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
    >
        <p className="text-lg italic mb-4">"{quote}"</p>
        <p className="font-semibold">{name}</p>
        <p className="text-sm text-gray-400">{company}</p>
    </motion.div>
);

export const scrollToBottom = () => {
    window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
    });
};


const ApplicationDevelopmentPage = () => {
    const [headerData] = useState({
        title: "Application Development Services",
        description: "We specialize in creating dynamic, high-performance applications tailored to your business needs.",
    });

    useDocumentTitle(headerData?.title.replace(/\b(what|is)\b/gi, ''));


    const [activeTab, setActiveTab] = useState(0);

    const tabContents = [
        { title: "Web Applications", content: "Our web applications are built with the latest technologies to ensure responsiveness, scalability, and optimal performance across all devices." },
        { title: "Mobile Apps", content: "We develop intuitive and feature-rich mobile applications for both iOS and Android platforms, ensuring a seamless user experience." },
        { title: "Enterprise Solutions", content: "Our enterprise solutions are designed to streamline your business processes, improve efficiency, and drive growth through custom software tailored to your specific needs." },
    ];

    return (
        <div className="min-h-screen mx-auto text-white bg-bg">
            <Header
                {...headerData}
                isExploreBtn={true}
                backgroundImage={App_dev_cover}
                backgroundVideo={CoverVideo}
                isVideo={true}
            />

            <main className="container md:w-11/12 mx-auto px-4 py-12 md:py-24">
                <section className="mb-20">
                    <motion.h2
                        className="text-3xl font-bold text-center mb-10"
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        Our Areas of Expertise
                    </motion.h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {expertiseData.map((item, index) => (
                            <ExpertiseCard key={index} {...item} />
                        ))}
                    </div>
                </section>

                <ParallaxSection>
                    <section className="mb-20 bg-opacity-5 bg-white my-12 md:my-24 px-4 mobile:py-6 md:p-10 rounded-lg shadow-lg">
                        <motion.h2
                            className="text-3xl font-bold text-center mb-10"
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            Our Development Process
                        </motion.h2>
                        <div className="space-y-6">
                            {processSteps.map((step) => (
                                <ProcessStep key={step.step} {...step} />
                            ))}
                        </div>
                    </section>
                </ParallaxSection>

                <section className="mb-20 md:mb-36">
                    <motion.h2
                        className="text-3xl font-bold text-center mb-10"
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        Our Solutions
                    </motion.h2>
                    <div className="flex justify-center mb-6 space-x-4">
                        {tabContents.map((tab, index) => (
                            <motion.button
                                key={index}
                                className={`px-4 py-2 rounded-full ${activeTab === index ? 'bg-custom-gradient text-white' : 'bg-opacity-10 bg-white'}`}
                                onClick={() => setActiveTab(index)}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                {tab.title}
                            </motion.button>
                        ))}
                    </div>
                    <motion.div
                        key={activeTab}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.5 }}
                        className="text-center max-w-2xl mx-auto"
                    >
                        <p>{tabContents[activeTab].content}</p>
                    </motion.div>
                </section>

                <ParallaxSection offset={-50}>
                    <section className="mb-20 md:mb-36 bg-opacity-5 bg-white p-10 rounded-lg shadow-lg">
                        <motion.h2
                            className="text-3xl font-bold text-center mb-10"
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            Why Choose Us
                        </motion.h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                            <motion.div
                                className="flex flex-col items-center text-center"
                                whileHover={{ scale: 1.05 }}
                            >
                                <Users size={48} className="bg-custom-gradient rounded-full p-2 mb-4" />
                                <h3 className="text-xl font-semibold mb-2">Expert Team</h3>
                                <p>Skilled professionals with years of experience in various technologies</p>
                            </motion.div>
                            <motion.div
                                className="flex flex-col items-center text-center"
                                whileHover={{ scale: 1.05 }}
                            >
                                <Zap size={48} className="bg-custom-gradient rounded-full p-2 mb-4" />
                                <h3 className="text-xl font-semibold mb-2">Fast Delivery</h3>
                                <p>Efficient processes to deliver high-quality applications on time</p>
                            </motion.div>
                            <motion.div
                                className="flex flex-col items-center text-center"
                                whileHover={{ scale: 1.05 }}
                            >
                                <CheckCircle size={48} className="bg-custom-gradient rounded-full p-2 mb-4" />
                                <h3 className="text-xl font-semibold mb-2">Quality Assurance</h3>
                                <p>Rigorous testing to ensure reliable and bug-free applications</p>
                            </motion.div>
                            <motion.div
                                className="flex flex-col items-center text-center"
                                whileHover={{ scale: 1.05 }}
                            >
                                <Cloud size={48} className="bg-custom-gradient rounded-full p-2 mb-4" />
                                <h3 className="text-xl font-semibold mb-2">Scalable Solutions</h3>
                                <p>Applications designed to grow with your business needs</p>
                            </motion.div>
                        </div>
                    </section>
                </ParallaxSection>

                <section className="mb-20 md:mb-36">
                    <motion.h2
                        className="text-3xl font-bold text-center mb-10"
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        Client Testimonials
                    </motion.h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {testimonialsData.map((testimonial, index) => (
                            <TestimonialCard key={index} {...testimonial} />
                        ))}
                    </div>
                </section>

                <section className="mb-20 md:mb-36 bg-opacity-5 bg-white p-10 rounded-lg shadow-lg">
                    <motion.h2
                        className="text-3xl font-bold text-center mb-10"
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        Our Impact
                    </motion.h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center ">
                        <div>
                            <p className="text-5xl font-bold  bg-custom-gradient bg-gradient-to-b from-theme to-transparent/1 bg-clip-text text-transparent mb-4">
                                <AnimatedCounter target={100} />+
                            </p>
                            <p className='text-md font-[600] opacity-90' >Successful Projects</p>
                        </div>
                        <div>
                            <p className="text-5xl font-bold bg-gradient-to-b from-theme to-transparent bg-clip-text text-transparent mb-4">
                                <AnimatedCounter target={50} />+
                            </p>
                            <p className='text-md font-[600] opacity-90'  >Happy Clients</p>
                        </div>
                        <div>
                            <p className="text-5xl font-bold bg-gradient-to-b from-theme to-transparent bg-clip-text text-transparent mb-4">
                                <AnimatedCounter target={5} />+
                            </p>
                            <p className='text-md font-[600] opacity-90' >Years of Excellence</p>
                        </div>
                    </div>
                </section>

                <ParallaxSection>
                    <section className="mb-20 md:mb-36">
                        <motion.h2
                            className="text-3xl font-bold text-center mb-10"
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            Our Technology Stack
                        </motion.h2>
                        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-8">
                            {['React', 'Node_js', 'Python', 'AWS', 'Docker', 'MongoDB', 'GraphQL', 'TypeScript', 'Flutter', 'Kubernetes', 'TensorFlow', 'Vue'].map((tech, index) => (
                                <motion.div
                                    key={index}
                                    className="flex flex-col items-center md:mb-12"
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    whileInView={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: 0.5, delay: index * 0.1 }}
                                >
                                    <img src={getTechImage(tech)} alt={tech} className="w-24 h-20 object-contain mb-2" />
                                    <p className="text-center">{tech}</p>
                                </motion.div>
                            ))}
                        </div>
                    </section>
                </ParallaxSection>

                {/* <section className="mb-20 md:mb-36 bg-opacity-5 bg-white p-10 rounded-lg shadow-lg">
                    <motion.h2
                        className="text-3xl font-bold text-center mb-10"
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        Get in Touch
                    </motion.h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <div>
                            <h3 className="text-2xl font-semibold mb-4">Contact Information</h3>
                            <div className="space-y-4">
                                <p className="flex items-center">
                                    <Mail className="mr-2" /> info@yourcompany.com
                                </p>
                                <p className="flex items-center">
                                    <Phone className="mr-2" /> +1 (123) 456-7890
                                </p>
                                <p className="flex items-center">
                                    <MapPin className="mr-2" /> 123 Tech Street, Silicon Valley, CA 94000
                                </p>
                            </div>
                        </div>
                        <form className="space-y-4">
                            <input
                                type="text"
                                placeholder="Your Name"
                                className="w-full p-2 rounded bg-opacity-20 bg-white text-white placeholder-gray-400"
                            />
                            <input
                                type="email"
                                placeholder="Your Email"
                                className="w-full p-2 rounded bg-opacity-20 bg-white text-white placeholder-gray-400"
                            />
                            <textarea
                                placeholder="Your Message"
                                rows="4"
                                className="w-full p-2 rounded bg-opacity-20 bg-white text-white placeholder-gray-400"
                            ></textarea>
                            <motion.button
                                className="bg-custom-gradient text-white font-bold py-3 px-8 rounded-full inline-flex items-center"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                Send Message <ArrowRight className="ml-2" />
                            </motion.button>
                        </form>
                    </div>
                </section> */}

                <section className="text-center">
                    <motion.h2
                        className="text-3xl font-bold mb-6"
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        Ready to Build Your Next Big Idea?
                    </motion.h2>
                    <motion.p
                        className="mb-8 max-w-2xl mx-auto"
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                    >
                        Let's collaborate to create innovative applications that drive your business forward. Our team of experts is ready to turn your vision into reality.
                    </motion.p>
                    <motion.button
                        className="bg-custom-gradient text-white font-bold py-3 px-8 rounded-full inline-flex items-center"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={scrollToBottom}
                    >
                        Start Your Project <ArrowDown className="ml-2" />
                    </motion.button>
                </section>
            </main>
            {/* 
            <footer className="bg-opacity-10 bg-white mt-20 py-10">
                <div className="container mx-auto px-4 text-center">
                    <p>&copy; 2024 Your Company Name. All rights reserved.</p>
                    <div className="mt-4 space-x-4">
                        <a href="#" className="text-gray-400 hover:text-white">Privacy Policy</a>
                        <a href="#" className="text-gray-400 hover:text-white">Terms of Service</a>
                        <a href="#" className="text-gray-400 hover:text-white">Careers</a>
                    </div>
                </div>
            </footer> */}

            <Footer />
        </div>
    );
};

export default ApplicationDevelopmentPage;