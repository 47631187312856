import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const MigrationFactoryBenefits = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const benefits = [
    {
      icon: '📋',
      title: 'Industry standardized Step-by-step processes',
      description: 'to enable frictionless SAP workloads, applications, asset migration to the cloud'
    },
    {
      icon: '🎯',
      title: 'Highly automation-rich approach',
      description: 'guaranteeing best results, regardless of ecosystem complexity and use-case scenario, no questions asked'
    },
    {
      icon: '🛡️',
      title: 'Automate SAP process management',
      description: 'and workflows with DIAA Corp proprietary intelligent RPA solutions dedicated to SAP practices'
    },
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? benefits.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === benefits.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="bg-[#0D0D2B] text-white p-4 sm:p-8 flex flex-col items-center py-12 sm:py-24">
      <h2 className="text-xl sm:text-2xl font-bold mb-8 sm:mb-12 text-center max-w-3xl px-4">
        Why Begin your SAP Cloud Evolution Journey with the Migration Factory Approach?
      </h2>

      <div className="relative w-full max-w-4xl">
        <div className="flex justify-center items-center">
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className={`bg-[#1A1A3A] rounded-lg p-4 sm:p-6 w-full sm:w-80 h-64 flex flex-col items-center justify-center text-center transition-all duration-300 ${
                index === currentIndex ? 'opacity-100 scale-100' : 'opacity-0 scale-95 hidden sm:flex'
              }`}
              style={{position: index === currentIndex ? 'relative' : 'absolute'}}
            >
              <div className="text-3xl sm:text-4xl mb-3 sm:mb-4">{benefit.icon}</div>
              <h3 className="text-base sm:text-lg font-semibold mb-2">{benefit.title}</h3>
              <p className="text-xs sm:text-sm text-gray-300 overflow-y-auto max-h-20">{benefit.description}</p>
            </div>
          ))}
        </div>

        <button
          onClick={handlePrev}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-theme p-2 rounded-full"
          aria-label="Previous benefit"
        >
          <FaChevronLeft />
        </button>
        <button
          onClick={handleNext}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-theme p-2 rounded-full"
          aria-label="Next benefit"
        >
          <FaChevronRight />
        </button>
      </div>

      <div className="flex justify-center mt-6 space-x-2">
        {benefits.map((_, index) => (
          <button
            key={index}
            className={`w-2 h-2 rounded-full transition-all duration-300 ${
              index === currentIndex ? 'bg-theme w-4' : 'bg-gray-500'
            }`}
            aria-label={`Go to benefit ${index + 1}`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default MigrationFactoryBenefits;